// Specific page components which are a fixed part of the page (as opposed to a flexible-content block)

// Page intro, list intro etc
.intro h1:only-child {
    margin-bottom: 2 * $global-margin;
}

.intro-text {
    margin-bottom: 1.5 * $global-margin;
}

.boxed-intro {

    // NB The breakpoint is set to when the headline styles change (in particular, the font size).
    @include breakpoint( medium ) {
        width: 80%;
        max-width: rem-calc( 610 );
        margin-left: auto;
        margin-right: auto;
    }
}

.limit-line-length {

    // Limits the width to ensure a line length which is still readable (up to 85 characters per line), based on body
    // text.
    //
    // We allow lines to get a little longer than that before we trigger the limit (ie, the activation breakpoint is
    // larger than it should be, if only line length were a concern). Otherwise, lines would be just a little bit
    // shorter than their container in the beginning, which looks like a mistake. Instead, we switch when lines are
    // distinctively shorter than their container is wide, which looks like a choice.
    @include breakpoint( 850 ) {
        max-width: rem-calc( 680 );
        margin-left: auto;
        margin-right: auto;
    }

}

// Page outro
.outro-text {
    margin-bottom: $global-margin;
}