$cc-text-color: get-palette-color( primary, light, 5 );
$cc-text-color-hover: get-palette-color( primary, light, 4 );
$cc-button-color: get-palette-color( primary );

.cc-grower {
    position: relative;
    z-index: $max-z-index;
}

.cc-window {
    z-index: $max-z-index;
    background-color: get-palette-color( neutral, dark, 5 );

    &.small-text {
        font-size: $small-font-size;
    }

    .cc-btn {
        background-color: $cc-button-color;
        border-color: $cc-button-color;
        color: $cc-text-color;
    }

    &.transparent-button .cc-btn {
        background-color: transparent;
        color: $cc-button-color;
        border-color: $cc-button-color;
    }

    &:not( .cc-floating ) .cc-btn {
        margin-left: $global-margin;
    }

    .cc-message {
        line-height: 1.2;
        color: $cc-text-color;

        .cc-link {
            &, &:visited {
                color: $cc-text-color;
            }

            &:hover, &:active, &:focus {
                color: $cc-text-color-hover;
            }
        }
    }

}

.cc-window.cc-theme-classic {

    .cc-btn {
        border-radius: $global-radius;
    }

}

