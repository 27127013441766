// Film data
.films-home .collections-list-item .title {

    // In the double-column film list **on the film overview page**, long titles break the layout for breakpoint
    // "large". ("Medium" switches to single-column layout, "xlarge" is big enough. Single-entry pages have one
    // column only and are not affected.)
    //
    // ATTN No longer necessary as the title font has become smaller. Reactivate in case of changes.

    //@include breakpoint( large only ) {
    //    @include header-font-size( h3 );
    //}

}

.films-home, .film-entry, .film-typography {

    .collections-list-item {

        .clip-type {
            @include header-styles( h4 );
            font-weight: 200;
            line-height: 0;
            color: $medium-gray;

            // Tweak the position
            transform: translateY( 0.12em );                                         // stylelint-disable-line unit-allowed-list
        }

    }

}

// Film entry page
.film-entry {

    .collections-list-item {
        margin-top: 2 * $global-margin;
    }

    .job-role {
        font-style: italic;
    }

}

