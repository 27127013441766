// Carousel and call-out
//
// Carousel is window-sized, inherits height from positioned parent, which is the root element
$callout-width-large: 500;
$callout-width-medium: 300;
$callout-width-small: 250;

$callout-height-large: 150;
$callout-height-medium: 100;
$callout-height-small: 80;

$dark-callout-color: get-palette-color( primary, dark, 2 );
$light-callout-color: get-palette-color( primary, light, 2 );
$callout-background-opacity-dark: 0.5;
$callout-background-opacity-light: 0.2;

$banner-box-fade-in-duration: 15s;

$button-fade-in-duration: 4s;           // for the regular fade-in

$button-hint-animation-duration: 4s;    // for the hint animation on small portrait screens; total time for all flashes; initial delay not included here
$button-hint-animation-delay: 1s;
$button-hint-flashes: 2;

$button-background-opacity-dark: 0.15;
$button-background-opacity-light: 0.25;


// NB These mixins are kept here because they are one-offs for the carousel, used as a workaround for duplicated code

// See https://stackoverflow.com/q/14840918/508355 for the reasons a placeholder and @extend can't be used here
@mixin visible-banner-box () {
    @include position-absolute-expand-to-context;

    content: " ";
    opacity: 0;

    border-radius: $global-radius;
    animation: banner-fade-in $banner-box-fade-in-duration ease-in-out both;
}

@mixin banner-background () {
    @include visible-banner-box;

    @at-root .is-light & {
        @include frosted-glass( $callout-background-opacity-light, "light" );
    }

    @at-root .is-dark & {
        @include frosted-glass( $callout-background-opacity-dark, "dark" );
    }
}

@mixin fullscreen-button-below-carousel {
    // NB `bottom` must work with the required height calculated in carousel.js where the .controls-below class is added
    // (_fullscreenWatchWindowResize())
    @include frosted-glass( $button-background-opacity-dark, "dark" );
    top: auto;
    bottom: rem-calc( -28 );
    right: 0;

    .flickity-button-icon {
        fill: $white;
    }
}

@mixin fullscreen-button-on-carousel {
    top: auto;
    bottom: rem-calc( 10 );

    .flickity-button-icon {
        fill: $white;

        @at-root .is-light & {
            opacity: 1;
        }

        @at-root .is-dark & {
            opacity: 0.4;
        }
    }

    .no-svg {
        color: $white;
    }
}

@mixin dot-on-carousel {
    @at-root .is-light & {
        background-color: $white;
        opacity: 0.5;

        &.is-selected {
            opacity: 1;
        }
    }

    @at-root .is-dark & {
        background-color: $white;
        opacity: 0.25;

        &.is-selected {
            opacity: 0.6;
        }
    }
}

@mixin auto-hide-nav-buttons ( $arrow-color: $white ) {
    transition: none !important;
    $animation-cycles: 2 * $button-hint-flashes;
    animation: hint ( $button-hint-animation-duration / $animation-cycles ) ease-in-out $button-hint-animation-delay $animation-cycles alternate both;

    .flickity-button-icon {
        fill: $arrow-color;
    }

    .no-svg {
        color: $arrow-color;
    }
}

// Animation
@keyframes hint {
    0% {
        opacity: 0;
    }

    70%, 100% {
        opacity: 0.8;
    }
}

@keyframes banner-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


// -----------------------------------------------
// Optimizations for a smooth carousel performance
// -----------------------------------------------

.flickity-slider {
    will-change: transform;
}

.carousel-cell:first-child, .carousel-cell:last-child {
    will-change: left;
}

.flickity-page-dots > .dot {
    will-change: opacity;
}


// -----------------------------------------------
// Content carousel and cells
// -----------------------------------------------

// Fixing the background flash in Safari when moving through the carousel. The flash is usually described as white, but
// in fact it is the background colour, whatever it is (but it defaults to white, hence the accounts of a white flash.)
//
// See
//
// - https://stackoverflow.com/q/2946748/508355
// - https://stackoverflow.com/q/3461441/508355
// - https://nathanhoad.net/how-to-stop-css-animation-flicker-in-webkit
// - https://www.viget.com/articles/webkit-transform-kill-the-flash/ (suggests translateZ(0) but didn't work, see below)
//
// Observations:
//
// - `translateZ(0)` did not add any value and caused the "slider forward" button to point to the wrong direction.
// - Adding `perspective` (or `-webkit-perspective`) did not improve things further.
// - Relying on either `transform-style` or `backface-visibility` on its own, and dropping the other one, did not fix
//   the white flash problem - both are needed.
// - Limiting the selector to just some of the elements made the flash reappear, all of the elements below must be
//   selected.
// - Selecting the content inside .carousel-cell (the img) did not make any difference.
// - Selecting some or all elements in .static-banner (ie, the .title-carousel) did not make any difference.
//
// There still is a white flash when a large image moves into view for the very first time. Nothing so far has fixed
// that. (Smaller image dimensions might.) The `object-cover` and `object-position` properties of the image might play
// into this. In any event, Safari on iOS seems to delay rendering until the image moves into view, and then it can't
// render it fast enough.
.content-carousel {
    // Default size: expand to container
    width: 100%;
    height: 100%;

    // Fix background flash
    .flickity-viewport, .flickity-slider, .carousel-cell {
        backface-visibility: hidden;
        transform-style: preserve-3d;
    }
}

.carousel-cell {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;

        // Prevent background bleed in case we use a border-radius, see
        // https://css-tricks.com/almanac/properties/b/border-radius/
        background-clip: padding-box;
    }
}


// -----------------------------------------------
// Fixed-ratio carousel
// -----------------------------------------------

.carousel-container.fixed-ratio {

    position: relative;

    .aspect-box {
        width: 100%;
        // Reset other styles, in case they are inherited (not necessary otherwise)
        height: 0;
        margin: 0;
        padding: 0;     // is overridden in the style attribute for the padding creating the aspect ratio.
    }

    .content-carousel {
        position: absolute;
        top: 0;
        left: 0;
    }

}


// -----------------------------------------------
// Window-filling carousel (HP)
// -----------------------------------------------

.carousel-container.fill-window {
    @include position-absolute-expand-to-context;

    // A dark background makes the background flash in iOS Safari a little less conspicuous (unless all images are
    // really bright, which they usually aren't).
    background-color: #101010;

    .content-carousel {
        width: 100vw;
    }

}


// -----------------------------------------------
// Carousel in full-screen mode
// -----------------------------------------------

// Tweaking (and reversing) the default style settings for full-screen mode.
// NB The baseline is defined in the flickity-fullscreen CSS
.carousel-container .content-carousel.is-fullscreen {
    background-color: transparent;
    padding-bottom: 0;
}

.carousel-container.carousel-fullscreen {

    // Mode for expanding images to full window width in full-screen mode (cropping them as needed) - requires the
    // .fill-window-in-fullscreen class
    &.fill-window-in-fullscreen .content-carousel {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }

}


// -----------------------------------------------
// Static banner
// -----------------------------------------------

.carousel-container.fill-window .static-banner {
    @include position-absolute-horizontally-centered( $callout-width-large );
    bottom: rem-calc( 100 );
    height: rem-calc( $callout-height-large );
    z-index: 4;

    @at-root .is-light &, .is-light & a {
        color: $dark-callout-color;
    }

    @at-root .is-dark &, .is-dark & a {
        color: $light-callout-color;
    }

    &.has-background {
        &::before {
            @include banner-background;
        }
    }

    &.has-border {
        &::before {
            @include visible-banner-box;
            border: rem-calc( 1 ) solid;
        }
    }

    // When the slider proceeds and the static banner is not visibly defined (no border or background), then the
    // text moving out of the banner disappears somewhere on the page at an invisible border. That looks
    // disconcerting. Fade the text out fast to suppress the effect.
    //
    // The .fade class is added and removed by event handlers for Flickity events.
    //
    // NB :not( .has-background ):not( .has-border ) is equivalent to :not( .has-background, .has-border ) but browser
    // support is much better.
    &:not( .has-background ):not( .has-border ) .fade {
        transition: opacity 300ms ease-out;
        opacity: 0;
    }

    .film-title {
        @include header-styles( h2 );
        font-family: get-typography-style-set-property( film, default-font-stack );
    }

    .play-button {
        @include header-styles( h5 );
    }

    @include breakpoint( smedium down ) {
        @include position-absolute-horizontally-centered( $callout-width-medium, "adjust" );
        height: rem-calc( $callout-height-medium );
    }

    @include breakpoint( small only ) {
        @include position-absolute-horizontally-centered( $callout-width-small, "adjust" );
        height: rem-calc( $callout-height-small );
    }

    // Move the static banner downwards on small screens - works better with the current images
    @include breakpoint( smedium down ) {
        bottom: rem-calc( 40 );
    }

    @media screen and #{breakpoint( smedium down )} and #{breakpoint( portrait )} {
        // Move the static banner downwards, but a little less, on small screens in portrait orientation - works
        // better with the current images
        bottom: rem-calc( 60 );

        // Always show the background box here (fade-in)
        &, &.has-border, &.has-background {
            &::before {
                @include banner-background;
                // Delay the fade-in until the button hint animation is nearly over
                animation-delay: $button-hint-animation-delay + $button-hint-animation-duration - 2s;
            }
        }
    }

}


// -------------------------------------------------
// Overlay buttons (prev/next, fullscreen): baseline
// -------------------------------------------------

// Applies to .flickity-prev-next-button, .flickity-fullscreen-button
//
// NB We need the additional specificity of these classes to override the styles in Flickity. Otherwise, as a selector,
// .flickity-button would be enough.
.flickity-button.flickity-prev-next-button, .flickity-button.flickity-fullscreen-button {
    opacity: 0;

    @at-root .is-light & {
        @include frosted-glass( $button-background-opacity-light, "light", $transition-duration: $button-fade-in-duration );

        // Applies to .arrow (path inside prev/next buttons) and the arrows inside the full-screen button (path doesn't
        // have a class)
        .flickity-button-icon {
            fill: $white;
        }

        .no-svg {
            color: $white;
        }
    }

    @at-root .is-dark & {
        @include frosted-glass( $button-background-opacity-dark, "dark", $transition-duration: $button-fade-in-duration );
    }

}


// --------------------------------------------------
// Full-screen button (while not in full-screen mode)
// --------------------------------------------------

.content-carousel:not( .is-fullscreen ) .flickity-fullscreen-button {
    @include fullscreen-button-below-carousel;
}


// -----------------------------------------------
// Prev/next buttons
// -----------------------------------------------

.flickity-prev-next-button {

    @at-root .auto-hide-nav-buttons-small-portrait-only & {

        @media screen and #{breakpoint( smedium down )} and #{breakpoint( portrait )} {
            @include auto-hide-nav-buttons;
        }

    }

    @at-root .auto-hide-nav-buttons & {
        @include auto-hide-nav-buttons;
    }

}


// -----------------------------------------------
// Window-filling carousel (HP): page dots
// -----------------------------------------------

.carousel-container.fill-window .flickity-page-dots {

    // Page dots placed on top of carousel content, rather than below it
    bottom: rem-calc( 28 );

    @include breakpoint( smedium down ) {
        bottom: rem-calc( 14 );
    }

    @media screen and #{breakpoint( smedium down )} and #{breakpoint( portrait )} {
        bottom: rem-calc( 20 );
    }

    .dot {
        @include dot-on-carousel;
    }
}


// ---------------------------------------------------------
// Carousel in full-screen mode: controls on top of carousel
// ---------------------------------------------------------

.content-carousel.is-fullscreen:not( .controls-below ) {

    .flickity-fullscreen-button {
        @include fullscreen-button-on-carousel;
    }

    .dot {
        @include dot-on-carousel;
    }

}

// -----------------------------------------------------
// Carousel in full-screen mode: controls below carousel
// -----------------------------------------------------

.content-carousel.is-fullscreen.controls-below {

    .flickity-fullscreen-button {
        @include fullscreen-button-below-carousel;
    }

    .flickity-page-dots {
        // NB `bottom` must work with the required height calculated in carousel.js where the .controls-below class is
        // added (_fullscreenWatchWindowResize())
        bottom: rem-calc( -24 );
    }

    .flickity-page-dots .dot {
        background-color: #333;
    }
}

