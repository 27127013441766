// stylelint-disable value-list-comma-newline-after, font-weight-notation, value-keyword-case
// sass-lint:disable no-duplicate-properties

// Font definitions
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// ATTN Character ranges:
//
// These fonts use the Latin and Latin Extended character ranges (as separate subsets, each in its own font file). If
// you need additional other ranges, download and add them here.
//
// (Use http://google-webfonts-helper.herokuapp.com/fonts for download.)
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// ## Fonts added as a precaution
//
// Even though all the fonts below are in use on the site, not all the weights and styles available here are. The
// "surplus" weights and styles are made available as a precaution: If they somehow end up on a page later on, perhaps
// as part of user-generated content and unforeseen italic or bold markup, the browser will be able to find and download
// what it needs.
//
// Likewise, the Extended Latin unicode range is usually not needed. Nevertheless, font files covering that range are
// made available here (as separate subsets). Browsers won't download them unless characters of that range appear (e.g.
// because of words in unexpected languages in user-generated content).
//
// ## Packages
//
// Fonts are grouped according to the pages they are used on. Build tags use those "packages" and translate them into
// link tags for font preloading. Here, the packages are merely documented. Create them in `build-config.js`.
//
// (1) Core package:
//     Fonts, variants and weights used on every page.
//
//     - Lato:                    normal 300
//     - Source Sans Pro Regular: normal 400
//     - Nunito Sans:             normal 200
//     - Lato Regular:            normal 400
//
//     Preload on every page.
//
// (2) Common package:
//     Font families, variants and weights used either certainly or potentially on nearly every page. Potential usage,
//     which is impossible to predict, is triggered e.g. if users apply bold or italic markup in rich text, or if
//     certain optional content categories appear on a page (e.g. press reviews).
//
//     - Source Sans Pro Italic:  italic 400  (potentially all pages with user-generated content)
//     - Source Sans Pro:         normal 700  (potentially all pages with user-generated content)
//
//     Preload everywhere except on HP; prefetch on HP.
//
// (3) Extended package for user-generated content:
//     Font families, variants and weights not used by default, and unlikely to be used even in user-generated content.
//     But they might still appear if users apply unconventional markup to their content (e.g. bold + italic).
//
//     - Source Sans Pro:         italic 700
//
//     Prefetch on all pages to have them in the cache when the time comes. But don't preload.
//
//     (Worst-case scenario: Visitor hits page with these font styles on cold cache. Worst-case result: Missing font
//     weights and styles get loaded while the page content is parsed. Faux face styles are used until they arrive. Is
//     acceptable.)
//
// (4) Special packages:
//     Font families, variants and weights used rarely, on some specific pages only.
//
//     - Quicksand Book:          normal normal (400)  (feed page; gallery lists and everywhere they could appear, ie all content home pages; gallery entries)
//     - Source Sans Pro:         normal 300           (feed page, recipe page)
//
//     Preload where applicable, prefetch everywhere else.
//
//     - Montserrat Regular:      normal 400           (system notice pages, ie maintenance and launch page)
//     - Raleway:                 normal 400           (system notice pages, ie maintenance and launch page)
//
//     Preload where applicable. Don't prefetch anywhere else, though.
//
// All other font variants are available here "just in case". Do not preload, do not prefetch.
//
// NB System fonts:
//
// - A sans-serif system font - Lucida Grande on the Mac, the sans-serif fallback (Arial) on Windows - is is used for
//   developer hint labels.
// - Cookie notice pop-ups (cookie consent) also use a system-native font: Helvetica, Calibri, Arial, sans-serif.
// - Vimeo currently uses Verdana inside the player iframe. This is none of our business and beyond our control anyway.
//
// There is no need to handle these fonts here. We'll simply use whatever is available on the system.

// ## List of font definitions in this file
//
// - Source Sans Pro
//   200, 200i, 300, 300i, 400, 400i, 600, 600i, 700, 700i, 900, 900i
//   CSS font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// - Nunito Sans
//   200, 200i, 300, 300i, 400, 400i, 600, 600i, 700, 700i, 800, 800i, 900, 900i
//   CSS font-family: "Nunito Sans", "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// - Lato
//   100, 100i, 300, 300i, 400, 400i, 700, 700i, 900, 900i
//   CSS font-family: Lato, "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// - Quicksand
//   300 (Light), 300i (Light Oblique), 400 = normal (Book), 400i = normal i (Book Oblique), 700 = bold (Bold),
//   700i = bold i (Bold Oblique)
//   CSS font-family: Quicksand, Lato, Montserrat, sans-serif
//
// - Montserrat
//   300, 300i, 400, 400i, 500, 500i
//   CSS font-family: Montserrat, "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// - Raleway
//   300, 300i, 400, 400i, 600, 600i
//   CSS font-family: Raleway, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;


// ---------
// Source Sans Pro (Google Fonts)
//
// Styles: 200, 200i, 300, 300i, 400, 400i, 600, 600i, 700, 700i, 900, 900i
// Subsets: latin, latin-ext (in separate files)
//
// CSS font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// Info:               https://fonts.google.com/specimen/Source+Sans+Pro
// Download:           http://google-webfonts-helper.herokuapp.com/fonts/source-sans-pro?subsets=latin
// Unicode range info: https://fonts.googleapis.com/css?family=Source+Sans+Pro
//
// NB Disabled `local()` loading:
//
// Access to locally installed font files, normally enabled with `local()`, is disabled here. Two distinct, somewhat
// different editions exist of this font: one distributed by Adobe Fonts, the other by Google Fonts. We use the Google
// font here.
//
// However, Firefox apparently loads whatever font of that name it finds locally. The Adobe version is rather common as
// a local install, and Firefox displays it, resulting in a far too fat/bold rendition. Hence, local() loading is
// disabled for Source Sans Pro.
//
// ---------

// Subset: Latin

// source-sans-pro-200 - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 200;
    src: url( "/static/fonts/source-sans-pro-v11-latin-200.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro ExtraLight" ), local( "SourceSansPro-ExtraLight" ),
        url( "/static/fonts/source-sans-pro-v11-latin-200.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-200.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-200.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-200.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-200.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-200italic - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 200;
    src: url( "/static/fonts/source-sans-pro-v11-latin-200italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro ExtraLight Italic" ), local( "SourceSansPro-ExtraLightItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-200italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-200italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-200italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-200italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-200italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-300 - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/source-sans-pro-v11-latin-300.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Light" ), local( "SourceSansPro-Light" ),
        url( "/static/fonts/source-sans-pro-v11-latin-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-300.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-300italic - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/source-sans-pro-v11-latin-300italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Light Italic" ), local( "SourceSansPro-LightItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-300italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-300italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-300italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-300italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-300italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-regular - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/source-sans-pro-v11-latin-regular.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Regular" ), local( "SourceSansPro-Regular" ),
        url( "/static/fonts/source-sans-pro-v11-latin-regular.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-regular.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-regular.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-regular.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-italic - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/source-sans-pro-v11-latin-italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Italic" ), local( "SourceSansPro-Italic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-600 - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    src: url( "/static/fonts/source-sans-pro-v11-latin-600.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro SemiBold" ), local( "SourceSansPro-SemiBold" ),
        url( "/static/fonts/source-sans-pro-v11-latin-600.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-600.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-600.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-600.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-600.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-600italic - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 600;
    src: url( "/static/fonts/source-sans-pro-v11-latin-600italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro SemiBold Italic" ), local( "SourceSansPro-SemiBoldItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-600italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-600italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-600italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-600italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-600italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-700 - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    src: url( "/static/fonts/source-sans-pro-v11-latin-700.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Bold" ), local( "SourceSansPro-Bold" ),
        url( "/static/fonts/source-sans-pro-v11-latin-700.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-700.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-700.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-700.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-700.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-700italic - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 700;
    src: url( "/static/fonts/source-sans-pro-v11-latin-700italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Bold Italic" ), local( "SourceSansPro-BoldItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-700italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-700italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-700italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-700italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-700italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-900 - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 900;
    src: url( "/static/fonts/source-sans-pro-v11-latin-900.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Black" ), local( "SourceSansPro-Black" ),
        url( "/static/fonts/source-sans-pro-v11-latin-900.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-900.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-900.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-900.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-900.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// source-sans-pro-900italic - latin
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 900;
    src: url( "/static/fonts/source-sans-pro-v11-latin-900italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Black Italic" ), local( "SourceSansPro-BlackItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-900italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-900italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-900italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-900italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-900italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}


// Subset: Extended Latin

// source-sans-pro-200 - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 200;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-200.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro ExtraLight" ), local( "SourceSansPro-ExtraLight" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-200italic - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 200;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-200italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro ExtraLight Italic" ), local( "SourceSansPro-ExtraLightItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-200italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-300 - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-300.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Light" ), local( "SourceSansPro-Light" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-300italic - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-300italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Light Italic" ), local( "SourceSansPro-LightItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-300italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-regular - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-regular.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Regular" ), local( "SourceSansPro-Regular" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-regular.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-regular.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-regular.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-regular.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-regular.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-italic - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Italic" ), local( "SourceSansPro-Italic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-600 - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-600.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro SemiBold" ), local( "SourceSansPro-SemiBold" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-600italic - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 600;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-600italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro SemiBold Italic" ), local( "SourceSansPro-SemiBoldItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-600italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-700 - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-700.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Bold" ), local( "SourceSansPro-Bold" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-700italic - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 700;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-700italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Bold Italic" ), local( "SourceSansPro-BoldItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-700italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-900 - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 900;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-900.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Black" ), local( "SourceSansPro-Black" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// source-sans-pro-900italic - latin-ext
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-weight: 900;
    src: url( "/static/fonts/source-sans-pro-v11-latin-ext-900italic.eot" );                                           // IE9 Compat Modes
    src:
        //local( "Source Sans Pro Black Italic" ), local( "SourceSansPro-BlackItalic" ),
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/source-sans-pro-v11-latin-ext-900italic.svg#SourceSansPro" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}


// ---------
// Nunito Sans (Google Fonts)
//
// Styles:  200, 200i, 300, 300i, 400, 400i, 600, 600i, 700, 700i, 800, 800i, 900, 900i
// Subsets: latin, latin-ext (in separate files)
//
// CSS font-family: "Nunito Sans", "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// Info:               https://fonts.google.com/specimen/Nunito+Sans
// Download:           http://google-webfonts-helper.herokuapp.com/fonts/nunito-sans?subsets=latin
// Unicode range info: https://fonts.googleapis.com/css?family=Nunito+Sans
// ---------

// Subset: Latin

// nunito-sans-200 - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 200;
    src: url( "/static/fonts/nunito-sans-v3-latin-200.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraLight" ), local( "NunitoSans-ExtraLight" ),
        url( "/static/fonts/nunito-sans-v3-latin-200.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-200.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-200.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-200.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-200.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-200italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 200;
    src: url( "/static/fonts/nunito-sans-v3-latin-200italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraLight Italic" ), local( "NunitoSans-ExtraLightItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-200italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-200italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-200italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-200italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-200italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-300 - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/nunito-sans-v3-latin-300.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Light" ), local( "NunitoSans-Light" ),
        url( "/static/fonts/nunito-sans-v3-latin-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-300.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-300italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/nunito-sans-v3-latin-300italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Light Italic" ), local( "NunitoSans-LightItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-300italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-300italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-300italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-300italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-300italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-regular - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/nunito-sans-v3-latin-regular.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Regular" ), local( "NunitoSans-Regular" ),
        url( "/static/fonts/nunito-sans-v3-latin-regular.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-regular.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-regular.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-regular.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-regular.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/nunito-sans-v3-latin-italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Italic" ), local( "NunitoSans-Italic" ),
        url( "/static/fonts/nunito-sans-v3-latin-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-600 - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    src: url( "/static/fonts/nunito-sans-v3-latin-600.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans SemiBold" ), local( "NunitoSans-SemiBold" ),
        url( "/static/fonts/nunito-sans-v3-latin-600.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-600.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-600.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-600.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-600.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-600italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 600;
    src: url( "/static/fonts/nunito-sans-v3-latin-600italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans SemiBold Italic" ), local( "NunitoSans-SemiBoldItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-600italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-600italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-600italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-600italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-600italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-700 - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    src: url( "/static/fonts/nunito-sans-v3-latin-700.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Bold" ), local( "NunitoSans-Bold" ),
        url( "/static/fonts/nunito-sans-v3-latin-700.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-700.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-700.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-700.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-700.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-700italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 700;
    src: url( "/static/fonts/nunito-sans-v3-latin-700italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Bold Italic" ), local( "NunitoSans-BoldItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-700italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-700italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-700italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-700italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-700italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-800 - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 800;
    src: url( "/static/fonts/nunito-sans-v3-latin-800.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraBold" ), local( "NunitoSans-ExtraBold" ),
        url( "/static/fonts/nunito-sans-v3-latin-800.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-800.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-800.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-800.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-800.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-800italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 800;
    src: url( "/static/fonts/nunito-sans-v3-latin-800italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraBold Italic" ), local( "NunitoSans-ExtraBoldItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-800italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-800italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-800italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-800italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-800italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-900 - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    src: url( "/static/fonts/nunito-sans-v3-latin-900.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Black" ), local( "NunitoSans-Black" ),
        url( "/static/fonts/nunito-sans-v3-latin-900.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-900.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-900.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-900.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-900.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// nunito-sans-900italic - latin
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 900;
    src: url( "/static/fonts/nunito-sans-v3-latin-900italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Black Italic" ), local( "NunitoSans-BlackItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-900italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-900italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-900italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-900italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-900italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// Subset: Extended Latin

// nunito-sans-200 - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 200;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-200.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraLight" ), local( "NunitoSans-ExtraLight" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-200.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-200.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-200.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-200.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-200.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-200italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 200;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-200italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraLight Italic" ), local( "NunitoSans-ExtraLightItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-200italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-200italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-200italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-200italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-200italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-300 - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-300.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Light" ), local( "NunitoSans-Light" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-300.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-300italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-300italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Light Italic" ), local( "NunitoSans-LightItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-300italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-300italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-300italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-300italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-300italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-regular - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-regular.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Regular" ), local( "NunitoSans-Regular" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-regular.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-regular.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-regular.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-regular.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-regular.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Italic" ), local( "NunitoSans-Italic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-600 - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-600.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans SemiBold" ), local( "NunitoSans-SemiBold" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-600.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-600.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-600.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-600.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-600.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-600italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 600;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-600italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans SemiBold Italic" ), local( "NunitoSans-SemiBoldItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-600italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-600italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-600italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-600italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-600italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-700 - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-700.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Bold" ), local( "NunitoSans-Bold" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-700.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-700.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-700.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-700.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-700.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-700italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 700;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-700italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Bold Italic" ), local( "NunitoSans-BoldItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-700italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-700italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-700italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-700italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-700italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-800 - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 800;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-800.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraBold" ), local( "NunitoSans-ExtraBold" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-800.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-800.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-800.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-800.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-800.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-800italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 800;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-800italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans ExtraBold Italic" ), local( "NunitoSans-ExtraBoldItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-800italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-800italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-800italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-800italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-800italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-900 - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 900;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-900.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Black" ), local( "NunitoSans-Black" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-900.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-900.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-900.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-900.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-900.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// nunito-sans-900italic - latin-ext
@font-face {
    font-family: "Nunito Sans";
    font-style: italic;
    font-weight: 900;
    src: url( "/static/fonts/nunito-sans-v3-latin-ext-900italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Nunito Sans Black Italic" ), local( "NunitoSans-BlackItalic" ),
        url( "/static/fonts/nunito-sans-v3-latin-ext-900italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/nunito-sans-v3-latin-ext-900italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-900italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/nunito-sans-v3-latin-ext-900italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/nunito-sans-v3-latin-ext-900italic.svg#NunitoSans" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}


// ---------
// Lato (Google Fonts)
//
// Styles:  100, 100i, 300, 300i, 400, 400i, 700, 700i, 900, 900i
// Subsets: latin, latin-ext (in separate files)
//
// CSS font-family: Lato, "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// Info:               https://fonts.google.com/specimen/Lato
// Download:           http://google-webfonts-helper.herokuapp.com/fonts/lato?subsets=latin
// Unicode range info: https://fonts.googleapis.com/css?family=Lato
// ---------

// Subset: Latin

// lato-100 - latin
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 100;
    src: url( "/static/fonts/lato-v14-latin-100.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Hairline" ), local( "Lato-Hairline" ),
        url( "/static/fonts/lato-v14-latin-100.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-100.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-100.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-100.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-100.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-100italic - latin
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 100;
    src: url( "/static/fonts/lato-v14-latin-100italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Hairline Italic" ), local( "Lato-HairlineItalic" ),
        url( "/static/fonts/lato-v14-latin-100italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-100italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-100italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-100italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-100italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-300 - latin
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/lato-v14-latin-300.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Light" ), local( "Lato-Light" ),
        url( "/static/fonts/lato-v14-latin-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-300.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-300italic - latin
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/lato-v14-latin-300italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Light Italic" ), local( "Lato-LightItalic" ),
        url( "/static/fonts/lato-v14-latin-300italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-300italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-300italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-300italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-300italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-regular - latin
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/lato-v14-latin-regular.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Regular" ), local( "Lato-Regular" ),
        url( "/static/fonts/lato-v14-latin-regular.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-regular.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-regular.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-regular.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-regular.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-italic - latin
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/lato-v14-latin-italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Italic" ), local( "Lato-Italic" ),
        url( "/static/fonts/lato-v14-latin-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-700 - latin
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    src: url( "/static/fonts/lato-v14-latin-700.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Bold" ), local( "Lato-Bold" ),
        url( "/static/fonts/lato-v14-latin-700.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-700.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-700.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-700.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-700.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-700italic - latin
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 700;
    src: url( "/static/fonts/lato-v14-latin-700italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Bold Italic" ), local( "Lato-BoldItalic" ),
        url( "/static/fonts/lato-v14-latin-700italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-700italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-700italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-700italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-700italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-900 - latin
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    src: url( "/static/fonts/lato-v14-latin-900.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Black" ), local( "Lato-Black" ),
        url( "/static/fonts/lato-v14-latin-900.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-900.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-900.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-900.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-900.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// lato-900italic - latin
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 900;
    src: url( "/static/fonts/lato-v14-latin-900italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Black Italic" ), local( "Lato-BlackItalic" ),
        url( "/static/fonts/lato-v14-latin-900italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-900italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-900italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-900italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-900italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// Subset: Extended Latin

// lato-100 - latin-ext
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 100;
    src: url( "/static/fonts/lato-v14-latin-ext-100.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Hairline" ), local( "Lato-Hairline" ),
        url( "/static/fonts/lato-v14-latin-ext-100.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-100.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-100.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-100.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-100.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-100italic - latin-ext
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 100;
    src: url( "/static/fonts/lato-v14-latin-ext-100italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Hairline Italic" ), local( "Lato-HairlineItalic" ),
        url( "/static/fonts/lato-v14-latin-ext-100italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-100italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-100italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-100italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-100italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-300 - latin-ext
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/lato-v14-latin-ext-300.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Light" ), local( "Lato-Light" ),
        url( "/static/fonts/lato-v14-latin-ext-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-300.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-300italic - latin-ext
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/lato-v14-latin-ext-300italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Light Italic" ), local( "Lato-LightItalic" ),
        url( "/static/fonts/lato-v14-latin-ext-300italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-300italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-300italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-300italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-300italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-regular - latin-ext
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/lato-v14-latin-ext-regular.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Regular" ), local( "Lato-Regular" ),
        url( "/static/fonts/lato-v14-latin-ext-regular.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-regular.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-regular.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-regular.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-regular.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-italic - latin-ext
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/lato-v14-latin-ext-italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Italic" ), local( "Lato-Italic" ),
        url( "/static/fonts/lato-v14-latin-ext-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-700 - latin-ext
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    src: url( "/static/fonts/lato-v14-latin-ext-700.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Bold" ), local( "Lato-Bold" ),
        url( "/static/fonts/lato-v14-latin-ext-700.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-700.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-700.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-700.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-700.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-700italic - latin-ext
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 700;
    src: url( "/static/fonts/lato-v14-latin-ext-700italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Bold Italic" ), local( "Lato-BoldItalic" ),
        url( "/static/fonts/lato-v14-latin-ext-700italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-700italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-700italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-700italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-700italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-900 - latin-ext
@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    src: url( "/static/fonts/lato-v14-latin-ext-900.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Black" ), local( "Lato-Black" ),
        url( "/static/fonts/lato-v14-latin-ext-900.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-900.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-900.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-900.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-900.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// lato-900italic - latin-ext
@font-face {
    font-family: Lato;
    font-style: italic;
    font-weight: 900;
    src: url( "/static/fonts/lato-v14-latin-ext-900italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Lato Black Italic" ), local( "Lato-BlackItalic" ),
        url( "/static/fonts/lato-v14-latin-ext-900italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/lato-v14-latin-ext-900italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-900italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/lato-v14-latin-ext-900italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/lato-v14-latin-ext-900italic.svg#Lato" ) format( "svg" );                 // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}


// ---------
// Quicksand
// ---------

// Styles:          300 (Light), 300i (Light Oblique), 400 = normal (Book), 400i = normal i (Book Oblique),
//                  700 = bold (Bold), 700i = bold i (Bold Oblique)
//
// CSS font-family: Quicksand, Lato, Montserrat, sans-serif
//
// NB The font version used here is obtained from Font Squirrel:
//
// - https://www.fontsquirrel.com/fonts/quicksand
//
// A newer version of Quicksand is available on Google Fonts:
//
// - https://fonts.google.com/specimen/Quicksand
// - http://google-webfonts-helper.herokuapp.com/fonts/quicksand?subsets=latin
//
// The new version is visually different ("improved"), though - looks less stylish. It might be worthwhile to consider
// exchanging the light and bold styles with newer versions (but they are not in active use on the site right now).
// However, DO NOT exchange the "old" Quicksand Book for the new "Quicksand Regular". The old version looks way more
// stylish.

// Quicksand Light
@font-face {
    font-family: Quicksand;
    src: url( "/static/fonts/Quicksand_Light.otf" );
    font-weight: 300;
    font-display: swap;
}

// Quicksand Light Oblique
@font-face {
    font-family: Quicksand;
    src: url( "/static/fonts/Quicksand_Light_Oblique.otf" );
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

// Quicksand Book
@font-face {
    font-family: Quicksand;
    src: url( "/static/fonts/Quicksand_Book.otf" );
    font-weight: normal;
    font-display: swap;
}

// Quicksand Book Oblique
@font-face {
    font-family: Quicksand;
    src: url( "/static/fonts/Quicksand_Book_Oblique.otf" );
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

// Quicksand Bold
@font-face {
    font-family: Quicksand;
    src: url( "/static/fonts/Quicksand_Bold.otf" );
    font-weight: bold;
    font-display: swap;
}

// Quicksand Bold Oblique
@font-face {
    font-family: Quicksand;
    src: url( "/static/fonts/Quicksand_Bold_Oblique.otf" );
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


// ---------
// Montserrat (Google Fonts)
//
// Styles:  300, 300i, 400, 400i, 500, 500i
// Subsets: latin, latin-ext (in separate files)
//
// CSS font-family: Montserrat, "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// Info:               https://fonts.google.com/specimen/Montserrat
// Download:           http://google-webfonts-helper.herokuapp.com/fonts/montserrat?subsets=latin
// Unicode range info: https://fonts.googleapis.com/css?family=Montserrat
// ---------

// Subset: Latin

// montserrat-300 - latin
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/montserrat-v12-latin-300.eot" );                                           // IE9 Compat Modes
    src:
        local( "Montserrat Light" ),
        local( "Montserrat-Light" ),
        url( "/static/fonts/montserrat-v12-latin-300.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-300.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-300.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-300.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-300.svg#Montserrat" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// montserrat-300italic - latin
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/montserrat-v12-latin-300italic.eot" );                                     // IE9 Compat Modes
    src:
        local( "Montserrat Light Italic" ),
        local( "Montserrat-LightItalic" ),
        url( "/static/fonts/montserrat-v12-latin-300italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-300italic.woff2" ) format( "woff2" ),                  // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-300italic.woff" ) format( "woff" ),                    // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-300italic.ttf" ) format( "truetype" ),                 // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-300italic.svg#Montserrat" ) format( "svg" );           // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// montserrat-regular - latin
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/montserrat-v12-latin-regular.eot" );                                       // IE9 Compat Modes
    src:
        local( "Montserrat Regular" ),
        local( "Montserrat-Regular" ),
        url( "/static/fonts/montserrat-v12-latin-regular.eot?#iefix" ) format( "embedded-opentype" ),   // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-regular.woff2" ) format( "woff2" ),                    // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-regular.woff" ) format( "woff" ),                      // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-regular.ttf" ) format( "truetype" ),                   // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-regular.svg#Montserrat" ) format( "svg" );             // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// montserrat-italic - latin
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/montserrat-v12-latin-italic.eot" );                                        // IE9 Compat Modes
    src:
        local( "Montserrat Italic" ),
        local( "Montserrat-Italic" ),
        url( "/static/fonts/montserrat-v12-latin-italic.eot?#iefix" ) format( "embedded-opentype" ),    // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-italic.woff2" ) format( "woff2" ),                     // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-italic.woff" ) format( "woff" ),                       // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-italic.ttf" ) format( "truetype" ),                    // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-italic.svg#Montserrat" ) format( "svg" );              // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// montserrat-500 - latin
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    src: url( "/static/fonts/montserrat-v12-latin-500.eot" );                                           // IE9 Compat Modes
    src:
        local( "Montserrat Medium" ),
        local( "Montserrat-Medium" ),
        url( "/static/fonts/montserrat-v12-latin-500.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-500.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-500.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-500.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-500.svg#Montserrat" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// montserrat-500italic - latin
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    src: url( "/static/fonts/montserrat-v12-latin-500italic.eot" );                                     // IE9 Compat Modes
    src:
        local( "Montserrat Medium Italic" ),
        local( "Montserrat-MediumItalic" ),
        url( "/static/fonts/montserrat-v12-latin-500italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-500italic.woff2" ) format( "woff2" ),                  // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-500italic.woff" ) format( "woff" ),                    // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-500italic.ttf" ) format( "truetype" ),                 // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-500italic.svg#Montserrat" ) format( "svg" );           // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// Subset: Extended Latin

// montserrat-300 - latin-ext
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/montserrat-v12-latin-ext-300.eot" );                                       // IE9 Compat Modes
    src:
        local( "Montserrat Light" ),
        local( "Montserrat-Light" ),
        url( "/static/fonts/montserrat-v12-latin-ext-300.eot?#iefix" ) format( "embedded-opentype" ),   // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-ext-300.woff2" ) format( "woff2" ),                    // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-300.woff" ) format( "woff" ),                      // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-300.ttf" ) format( "truetype" ),                   // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-ext-300.svg#Montserrat" ) format( "svg" );             // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// montserrat-regular - latin-ext
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/montserrat-v12-latin-ext-regular.eot" );                                   // IE9 Compat Modes
    src:
        local( "Montserrat Regular" ),
        local( "Montserrat-Regular" ),
        url( "/static/fonts/montserrat-v12-latin-ext-regular.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-ext-regular.woff2" ) format( "woff2" ),                // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-regular.woff" ) format( "woff" ),                  // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-regular.ttf" ) format( "truetype" ),               // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-ext-regular.svg#Montserrat" ) format( "svg" );         // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// montserrat-300italic - latin-ext
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/montserrat-v12-latin-ext-300italic.eot" );                                 // IE9 Compat Modes
    src:
        local( "Montserrat Light Italic" ),
        local( "Montserrat-LightItalic" ),
        url( "/static/fonts/montserrat-v12-latin-ext-300italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-ext-300italic.woff2" ) format( "woff2" ),              // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-300italic.woff" ) format( "woff" ),                // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-300italic.ttf" ) format( "truetype" ),             // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-ext-300italic.svg#Montserrat" ) format( "svg" );       // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// montserrat-italic - latin-ext
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/montserrat-v12-latin-ext-italic.eot" );                                    // IE9 Compat Modes
    src:
        local( "Montserrat Italic" ),
        local( "Montserrat-Italic" ),
        url( "/static/fonts/montserrat-v12-latin-ext-italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-ext-italic.woff2" ) format( "woff2" ),                 // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-italic.woff" ) format( "woff" ),                   // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-italic.ttf" ) format( "truetype" ),                // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-ext-italic.svg#Montserrat" ) format( "svg" );          // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// montserrat-500 - latin-ext
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    src: url( "/static/fonts/montserrat-v12-latin-ext-500.eot" );                                       // IE9 Compat Modes
    src:
        local( "Montserrat Medium" ),
        local( "Montserrat-Medium" ),
        url( "/static/fonts/montserrat-v12-latin-ext-500.eot?#iefix" ) format( "embedded-opentype" ),   // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-ext-500.woff2" ) format( "woff2" ),                    // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-500.woff" ) format( "woff" ),                      // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-500.ttf" ) format( "truetype" ),                   // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-ext-500.svg#Montserrat" ) format( "svg" );             // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// montserrat-500italic - latin-ext
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    src: url( "/static/fonts/montserrat-v12-latin-ext-500italic.eot" );                                 // IE9 Compat Modes
    src:
        local( "Montserrat Medium Italic" ),
        local( "Montserrat-MediumItalic" ),
        url( "/static/fonts/montserrat-v12-latin-ext-500italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/montserrat-v12-latin-ext-500italic.woff2" ) format( "woff2" ),              // Super Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-500italic.woff" ) format( "woff" ),                // Modern Browsers
        url( "/static/fonts/montserrat-v12-latin-ext-500italic.ttf" ) format( "truetype" ),             // Safari, Android, iOS
        url( "/static/fonts/montserrat-v12-latin-ext-500italic.svg#Montserrat" ) format( "svg" );       // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}


// ---------
// Raleway (Google Fonts)
//
// Styles: 300, 300i, 400, 400i, 600, 600i
// Subsets: latin, latin-ext (in separate files)
//
// CSS font-family: Raleway, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//
// Info:               https://fonts.google.com/specimen/Raleway
// Download:           http://google-webfonts-helper.herokuapp.com/fonts/raleway?subsets=latin
// Unicode range info: https://fonts.googleapis.com/css?family=Raleway
// ---------

// Subset: Latin

// raleway-300 - latin
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/raleway-v12-latin-300.eot" );                                              // IE9 Compat Modes
    src:
        local( "Raleway Light" ),
        local( "Raleway-Light" ),
        url( "/static/fonts/raleway-v12-latin-300.eot?#iefix" ) format( "embedded-opentype" ),          // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-300.woff2" ) format( "woff2" ),                           // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-300.woff" ) format( "woff" ),                             // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-300.ttf" ) format( "truetype" ),                          // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-300.svg#Raleway" ) format( "svg" );                       // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// raleway-300italic - latin 
@font-face {
    font-family: Raleway;
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/raleway-v12-latin-300italic.eot" );                                        // IE9 Compat Modes
    src:
        local( "Raleway Light Italic" ),
        local( "Raleway-LightItalic" ),
        url( "/static/fonts/raleway-v12-latin-300italic.eot?#iefix" ) format( "embedded-opentype" ),    // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-300italic.woff2" ) format( "woff2" ),                     // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-300italic.woff" ) format( "woff" ),                       // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-300italic.ttf" ) format( "truetype" ),                    // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-300italic.svg#Raleway" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// raleway-regular - latin 
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/raleway-v12-latin-regular.eot" );                                          // IE9 Compat Modes
    src:
        local( Raleway ),
        local( "Raleway-Regular" ),
        url( "/static/fonts/raleway-v12-latin-regular.eot?#iefix" ) format( "embedded-opentype" ),      // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-regular.woff2" ) format( "woff2" ),                       // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-regular.woff" ) format( "woff" ),                         // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-regular.ttf" ) format( "truetype" ),                      // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-regular.svg#Raleway" ) format( "svg" );                   // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// raleway-italic - latin 
@font-face {
    font-family: Raleway;
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/raleway-v12-latin-italic.eot" );                                           // IE9 Compat Modes
    src:
        local( "Raleway Italic" ),
        local( "Raleway-Italic" ),
        url( "/static/fonts/raleway-v12-latin-italic.eot?#iefix" ) format( "embedded-opentype" ),       // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-italic.woff2" ) format( "woff2" ),                        // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-italic.woff" ) format( "woff" ),                          // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-italic.ttf" ) format( "truetype" ),                       // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-italic.svg#Raleway" ) format( "svg" );                    // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// raleway-600 - latin 
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    src: url( "/static/fonts/raleway-v12-latin-600.eot" );                                              // IE9 Compat Modes
    src:
        local( "Raleway SemiBold" ),
        local( "Raleway-SemiBold" ),
        url( "/static/fonts/raleway-v12-latin-600.eot?#iefix" ) format( "embedded-opentype" ),          // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-600.woff2" ) format( "woff2" ),                           // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-600.woff" ) format( "woff" ),                             // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-600.ttf" ) format( "truetype" ),                          // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-600.svg#Raleway" ) format( "svg" );                       // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// raleway-600italic - latin 
@font-face {
    font-family: Raleway;
    font-style: italic;
    font-weight: 600;
    src: url( "/static/fonts/raleway-v12-latin-600italic.eot" );                                        // IE9 Compat Modes
    src:
        local( "Raleway SemiBold Italic" ),
        local( "Raleway-SemiBoldItalic" ),
        url( "/static/fonts/raleway-v12-latin-600italic.eot?#iefix" ) format( "embedded-opentype" ),    // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-600italic.woff2" ) format( "woff2" ),                     // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-600italic.woff" ) format( "woff" ),                       // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-600italic.ttf" ) format( "truetype" ),                    // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-600italic.svg#Raleway" ) format( "svg" );                 // Legacy iOS
    unicode-range:
        U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
        U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

// Subset: Extended Latin

// raleway-300 - latin-ext 
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    src: url( "/static/fonts/raleway-v12-latin-ext-300.eot" );                                          // IE9 Compat Modes
    src:
        local( "Raleway Light" ),
        local( "Raleway-Light" ),
        url( "/static/fonts/raleway-v12-latin-ext-300.eot?#iefix" ) format( "embedded-opentype" ),      // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-ext-300.woff2" ) format( "woff2" ),                       // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-300.woff" ) format( "woff" ),                         // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-300.ttf" ) format( "truetype" ),                      // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-ext-300.svg#Raleway" ) format( "svg" );                   // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// raleway-300italic - latin-ext 
@font-face {
    font-family: Raleway;
    font-style: italic;
    font-weight: 300;
    src: url( "/static/fonts/raleway-v12-latin-ext-300italic.eot" );                                    // IE9 Compat Modes
    src:
        local( "Raleway Light Italic" ),
        local( "Raleway-LightItalic" ),
        url( "/static/fonts/raleway-v12-latin-ext-300italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-ext-300italic.woff2" ) format( "woff2" ),                 // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-300italic.woff" ) format( "woff" ),                   // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-300italic.ttf" ) format( "truetype" ),                // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-ext-300italic.svg#Raleway" ) format( "svg" );             // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// raleway-regular - latin-ext 
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    src: url( "/static/fonts/raleway-v12-latin-ext-regular.eot" );                                      // IE9 Compat Modes
    src:
        local( "Raleway" ),
        local( "Raleway-Regular" ),
        url( "/static/fonts/raleway-v12-latin-ext-regular.eot?#iefix" ) format( "embedded-opentype" ),  // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-ext-regular.woff2" ) format( "woff2" ),                   // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-regular.woff" ) format( "woff" ),                     // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-regular.ttf" ) format( "truetype" ),                  // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-ext-regular.svg#Raleway" ) format( "svg" );               // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// raleway-italic - latin-ext 
@font-face {
    font-family: Raleway;
    font-style: italic;
    font-weight: 400;
    src: url( "/static/fonts/raleway-v12-latin-ext-italic.eot" );                                       // IE9 Compat Modes
    src:
        local( "Raleway Italic" ),
        local( "Raleway-Italic" ),
        url( "/static/fonts/raleway-v12-latin-ext-italic.eot?#iefix" ) format( "embedded-opentype" ),   // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-ext-italic.woff2" ) format( "woff2" ),                    // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-italic.woff" ) format( "woff" ),                      // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-italic.ttf" ) format( "truetype" ),                   // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-ext-italic.svg#Raleway" ) format( "svg" );                // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// raleway-600 - latin-ext 
@font-face {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    src: url( "/static/fonts/raleway-v12-latin-ext-600.eot" );                                          // IE9 Compat Modes
    src:
        local( "Raleway SemiBold" ),
        local( "Raleway-SemiBold" ),
        url( "/static/fonts/raleway-v12-latin-ext-600.eot?#iefix" ) format( "embedded-opentype" ),      // IE6-IE8
        url( "/static/fonts/raleway-v12-latin-ext-600.woff2" ) format( "woff2" ),                       // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-600.woff" ) format( "woff" ),                         // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-600.ttf" ) format( "truetype" ),                      // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-ext-600.svg#Raleway" ) format( "svg" );                   // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

// raleway-600italic - latin-ext 
@font-face {
    font-family: Raleway;
    font-style: italic;
    font-weight: 600;
    src: url( "/static/fonts/raleway-v12-latin-ext-600italic.eot" );                                    // IE9 Compat Modes
    src:
        local( "Raleway SemiBold Italic" ),
        local( "Raleway-SemiBoldItalic" ),
        url( "/static/fonts/raleway-v12-latin-ext-600italic.eot?#iefix" ) format( "embedded-opentype" ), // IE6-IE8 
        url( "/static/fonts/raleway-v12-latin-ext-600italic.woff2" ) format( "woff2" ),                 // Super Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-600italic.woff" ) format( "woff" ),                   // Modern Browsers
        url( "/static/fonts/raleway-v12-latin-ext-600italic.ttf" ) format( "truetype" ),                // Safari, Android, iOS
        url( "/static/fonts/raleway-v12-latin-ext-600italic.svg#Raleway" ) format( "svg" );             // Legacy iOS
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}
