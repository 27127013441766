// Temporary hacks
//
// This is where the shortcuts and quick hacks should go. When all is done, this file should be empty.

// todo remove hacks!
// sass-lint:disable nesting-depth

.home {

    // (a) old-style blocks: headline, banner image, text (2 items per row on larger screens)
    .featured-items .image-block {
        //margin-bottom: 1.25rem;
        //
        //@include breakpoint( smedium down ) {
        //    margin-bottom: 0.75rem;
        //}
    }

    // (b) Sequential full-width items with floating image, headline, text.

    // Aligns floating images with the headline next to it
    @include breakpoint( $image-block-float-switch-breakpoint-up ) {

        .featured-items .image-block.will-float {
            margin-top: 0.75 * $global-margin;
        }

    }

    // On small screens, reduce the distance between a full-width book cover image and the book headline below it
    @include breakpoint( $image-block-float-switch-breakpoint-down ) {
        .image-block {
            // todo this should be handled in the image-block SCSS, which doesn't change the bottom margin (yet) when
            //  the image goes full width on small screens. Is there a reason?
            margin-bottom: 1.25 * $global-margin;

            + h2 {
                margin-top: 0;
            }
        }
    }

}

.buecher-home {

    .book {

        // Enforces left-aligned, non-justified subtitles in the book description, regardless of the overall alignment
        // setting for the description (applies to paragraph text)
        .book-description {
            h1, h2, h3, h4, h5, h6 {
                text-align: left;
            }
        }

        // Aligns floating book cover images with the book headline next to it
        @include breakpoint( $image-block-float-switch-breakpoint-up ) {

            .image-block.will-float {
                margin-top: 0.75 * $global-margin;
            }

        }

    }

}