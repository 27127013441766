$logo-orange: rgb( 244, 102, 0 );
$logo-blue: rgb( 87, 190, 226 );
$neutral-color: rgb( 118, 118, 120 );

$color-palette: get-color-palette(
    (
        primary: get-base-color( $logo-blue, 0.75 ),
        secondary: $logo-orange,
        neutral: $neutral-color
    )
);

$max-z-index: 999;
