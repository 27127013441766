// Link to Craft backend
#cp-link {
    position: fixed;
    bottom: rem-calc( 1 );
    left: rem-calc( 1 );
    z-index: 1000;
    background-image: linear-gradient( #da5a47, #cc5643 );
    border-radius: $global-radius;

    &, a {
        font: bold 0.7rem "Helvetica Neue", HelveticaNeue, sans-serif;
        color: #ffffff;
    }

    a {
        padding: 1.5ch 2.5ch;                                                   // stylelint-disable-line unit-allowed-list
        display: block;
    }
}

