.home {
    // (a) old-style blocks: headline, banner image, text (2 items per row on larger screens)
    // -> see hacks file

    // (b) Sequential full-width items with floating image, headline, text.
    // todo we need a switch / flag class to distinguish between layout (a), above, and (b)
    .featured-items {
        margin-top: 0.5 * $global-margin;

        @include breakpoint( $image-block-float-switch-breakpoint-up ) {
            margin-top: 2 * $global-margin;
        }

        .flexible-content {
            clear: both;
            margin-bottom: 2 * $global-margin;

            @include breakpoint( $image-block-float-switch-breakpoint-up ) {
                margin-bottom: 3 * $global-margin;
            }
        }
    }

    .intro-text.boxed-intro {
        margin-bottom: 1.5 * $global-margin;

        // NB The breakpoint is set to when the "boxed" effect sets in.
        @include breakpoint( medium ) {
            margin-bottom: 3.5 * $global-margin;
        }
    }
}
