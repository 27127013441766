// Configuration for the media block component (gallery blocks, video blocks)

$media-block-layout-switch-breakpoint: large;
$media-block-float-switch-breakpoint-up: smedium;
$media-block-float-switch-breakpoint-down: msmall down;

$media-block-top-spacing: 0.25 * $global-margin;                // Compromise, works for approximate alignment next to headlines or paragraph text.
$media-block-side-spacing: 3 * $global-padding;                 // e.g. when floating left or right

$media-block-full-width-vertical-spacing: 3 * $global-padding;  // Space above and below the block when its position is set to "full"
$media-block-centered-vertical-spacing: 2.5 * $global-padding;  // Space above and below the block when its position is set to "center"
