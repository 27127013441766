// # Typography tools and initialization of the baseline typography style
//
// ## Baseline typography
//
// The baseline typography (the default styles) are defined in config/foundation/settings. They are captured here and
// become available under the typography style name `baseline`. That name can usually be omitted, it is the default for
// all tools with a typography style argument.
//
// ## API
//
// Emitting individual typography styles for header or body in style sheets:
//
// - If you want to access header styles in a style sheet, and output them **including** the selector, use the
//   typography-styles mixin:
//
//       @include typography-styles( name-of-typography-style, h2 h3 );
//
// - If you want to access header styles in a style sheet, and output them **without** the selector, use the
//   header-styles mixin:
//
//      @include header-styles( h2, all, all, name-of-typography-style );
//
// - Also use header-styles if you want to restrict output to a specific set of breakpoints, or to a specific set of
//   properties:
//
//       @include header-styles( h1, medium large, font-size line-height );
//
// - If you want to access body styles in a style sheet, use the typography-styles mixin:
//
//       @include typography-styles( name-of-typography-style, body );
//
//   (Body styles are never emitted with a selector, and don't support variation by breakpoint).
//
// - There is a simplified header-font-size mixin to access the header font sizes:
//
//       header-font-size( h2 );
//       header-font-size( h2, small medium );
//       header-font-size( h2, small medium, name-of-typography-style )
//
//   (You can achieve the same thing with the header-styles mixin.)
//
// If you have a choice, it is preferable to emit style properties without a selector, rather than having the selector
// generated by the mixin. Generated selectors are more difficult to trace in browser dev tools: The style source points
// to the origin of the selector, ie the mixin, not to the place where the mixin is included.


// Global variables for breakpoints
//
// NB These variables also exist, under a different name, as internal variables in Foundation. But because it seems
// unwise to use them, they are recreated here.

// List of breakpoint names, in ascending order. Expects the breakpoints map to be ordered from smallest to largest
// breakpoint. Foundation seems to expect that, too.
$breakpoint-names-all: map-keys( $breakpoints );            // in Foundation: $-zf-breakpoints-keys

// The smallest breakpoint, beginning at width 0.
$zero-breakpoint: first( $breakpoint-names-all );           // in Foundation: $-zf-zero-breakpoint


// The global typography-styles map
$typography-styles: ();


// ---------------------------------------------------------------------------------------------------------------------

// The list of supported header properties.
//
// NB If a property needs to be added, insert it into this list AND add a default value for it in
// _get-header-property-system-default()
$_supported-header-property-names: ( font-family font-size line-height margin-top margin-bottom color font-weight font-style font-variant text-transform text-rendering text-align );


// Internal helper. Returns the default value for a header property (e.g. margin-top), as defined in Foundation.
//
// Also supports the shorthands which Foundation allows in a header style map (e.g `mt` for `margin-top`).
//
// NB:
//
// - For `font-family`, typographic styles should use a different default: the `default-font-stack` defined in the
//   typographic style set. Therefore, a warning is emitted if the generic default is requested.
//
// - There is a universal default value for `font-size` (1rem), but it is hardly ever used. Actual Foundation defaults
//   depend on the header type (e.g. h1), and that is not handled here. In any event, the base font size should be
//   defined in the typographic style and never fall back to a generic default. A warning is emitted.
//
@function _get-header-property-system-default ( $property-name ) {
    @if $property-name == font-family {
        @warn "The generic, system-wide default value for the `font-family` of a header has been requested. Typographic style sets should use their default-font-stack property instead, and never fall back on a generic default.";
        @return $header-font-family;
    } @else if $property-name == font-size or $property-name == fs {
        @warn "The generic, system-wide default value for the `font-size` property of a header has been requested. Note that separate defaults also exist for each header type (e.g. h2), but they are not retrieved here. In any event, typographic style sets should define a default font-size for each header type (ie, a font-size for the smallest breakpoint), and never fall back on a generic default.";
        @return 1rem;
    } @else if $property-name == line-height or $property-name == lh {
        @return $header-lineheight;
    } @else if $property-name == margin-top or $property-name == mt {
        @return 0;
    } @else if $property-name == margin-bottom or $property-name == mb {
        @return $header-margin-bottom;
    } @else if $property-name == color {
        @return $header-color;
    } @else if $property-name == font-weight {
        @return $header-font-weight;
    } @else if $property-name == font-style {
        @return $header-font-style;
    } @else if $property-name == font-variant {
        @return normal;
    } @else if $property-name == text-transform {
        @return none;
    } @else if $property-name == text-rendering {
        @return $header-text-rendering;
    } @else if $property-name == text-align {
        @return left;
    } @else {
        @error "Cannot retrieve a generic, system-wide default value for header property `#{$property-name}`";
    }
}

// Internal helper. Returns a typographic style set (map) from the global $typography-styles map.
@function _get-typography-style-set ( $name ) {
    // Get the typographic style set
    $error-info: ( key-label: "typography style name", map-label: "the global $typography-styles map" );
    @return map-get-verbose( $typography-styles, $name, $error-info );
}

// Returns a top-level property in a typographic style set.
@function get-typography-style-set-property ( $typography-style-name, $property-name ) {
    $typography-style-set: _get-typography-style-set( $typography-style-name );

    $error-info: ( key-label: "required property", map-label: 'the entry for typography style "#{$typography-style-name}", in the global $typography-styles map' );
    @return map-get-verbose( $typography-style-set, $property-name, $error-info );
}

// Internal helper. Returns a single property value for a header type (such as h3) at a specified breakpoint.
//
// If aliases exist for the property name in the breakpoints map (such as `font-size`/`fs`), the property has to be
// passed as a space-separated list of the aliases.
//
// A default value for the smallest breakpoint must be provided because typography maps are not complete. They usually
// just contain the non-default settings. The default must be filled in by the caller.
@function _get-header-style ( $typography-style-name, $header-type, $breakpoint-name, $property, $zero-breakpoint-default-value: auto-detect ) {
    $value: null;

    @if length( $property ) > 1 {
        // Handle the aliases. Make a recursive call for each alias, but stop when a value is found. Don't provide the
        // real default value here because it could mask the fact that the entry is missing.
        @each $property-alias in $property {
            @if $value == null {
                $value: _get-header-style( $typography-style-name, $header-type, $breakpoint-name, $property-alias, null );
            }
        }
    } @else {
        $header-style-map: get-typography-style-set-property( $typography-style-name, header-styles );
        @if map-deep-has( $header-style-map, $breakpoint-name, $header-type, $property ) {
            // See https://github.com/zurb/foundation-sites/issues/9624#issuecomment-271438326
            $value: map-deep-get( $header-style-map, $breakpoint-name, $header-type, $property );
        }
    }

    @if $value == null and $breakpoint-name == $zero-breakpoint {
        $value: if( $zero-breakpoint-default-value == auto-detect, _get-header-property-system-default( first( $property ) ), $zero-breakpoint-default-value );
    }

    @return $value;
}

// Utility functions returning a property value for a header type (e.g. h3) at a specific breakpoint.

@function header-font-family ( $header-type, $breakpoint-name, $typography-style-name: baseline ) {
    $default-font-stack: get-typography-style-set-property( $typography-style-name, default-font-stack );
    @return _get-header-style( $typography-style-name, $header-type, $breakpoint-name, font-family, $default-font-stack );
}

@function header-font-size ( $header-type, $breakpoint-name, $typography-style-name: baseline ) {
    $font-size: _get-header-style( $typography-style-name, $header-type, $breakpoint-name, ( font-size fs ) );
    @return if( $font-size != null, rem-calc( $font-size ), null );
}

@function header-line-height ( $header-type, $breakpoint-name, $typography-style-name: baseline ) {
    $font-size: header-font-size( $header-type, $breakpoint-name );
    $line-height: _get-header-style( $typography-style-name, $header-type, $breakpoint-name, ( line-height lh ) );
    @return if( $font-size != null and $line-height != null, unitless-calc( $line-height, $font-size ), null );
}

@function header-margin-top ( $header-type, $breakpoint-name, $typography-style-name: baseline ) {
    $margin-top: _get-header-style( $typography-style-name, $header-type, $breakpoint-name, ( margin-top mt ) );
    @return if( $margin-top != null and $margin-top != 0, rem-calc( $margin-top ), $margin-top );
}

@function header-margin-bottom ( $header-type, $breakpoint-name, $typography-style-name: baseline ) {
    $margin-bottom: _get-header-style( $typography-style-name, $header-type, $breakpoint-name, ( margin-bottom mb ) );
    @return if( $margin-bottom != null and $margin-bottom != 0, rem-calc( $margin-bottom ), $margin-bottom );
}

// Emits the font-size property for a given header type (e.g. h3). Does not include the selector, just the property.
//
// The styles for the smallest breakpoint (base styles) are not wrapped in a `@include breakpoint` mixin, but the others
// are. If you only request a single breakpoint, however, the breakpoint wrapper is omitted (e.g. for
// `header-styles( h1, large )`).
//
// todo add support for "flattened" output if only one style is requested, ie all effective styles from the breakpoint
// todo   sequence are aggregated and returned, up to the requested breakpoint.
@mixin header-font-size ( $header-type, $breakpoint-names: all, $typography-style-name: baseline ) {
    $breakpoint-names: if( $breakpoint-names == all, $breakpoint-names-all, $breakpoint-names );

    @each $requested-breakpoint in $breakpoint-names {
        $header-size: header-font-size( $header-type, $requested-breakpoint, $typography-style-name );

        @if $header-size != null {
            @if $requested-breakpoint == $zero-breakpoint or length( $breakpoint-names ) == 1 {
                font-size: $header-size;
            } @else {
                @include breakpoint( $requested-breakpoint ) {
                    font-size: $header-size;
                }
            }
        }
    }
}

// Internal helper. Expects a map of style properties and merges a property key-value pair for a header style, provided
// that the property is defined at the requested breakpoint. Returns the modified or unaltered map.
@function _merge-header-style ( $styles-map, $property-name, $header-type, $breakpoint-name, $typography-style-name ) {
    $value: null;

    @if $property-name == font-family {
        $value: header-font-family( $header-type, $breakpoint-name, $typography-style-name );
    } @else if $property-name == font-size {
        $value: header-font-size( $header-type, $breakpoint-name, $typography-style-name );
    } @else if $property-name == line-height {
        $value: header-line-height( $header-type, $breakpoint-name, $typography-style-name );
    } @else if $property-name == margin-top {
        $value: header-margin-top( $header-type, $breakpoint-name, $typography-style-name );
    } @else if $property-name == margin-bottom {
        $value: header-margin-bottom( $header-type, $breakpoint-name, $typography-style-name );
    } @else {
        $value: _get-header-style( $typography-style-name, $header-type, $breakpoint-name, $property-name );
    }

    @return map-add-property-if-not-null( $styles-map, $property-name, $value );
}

// Returns the styles for a given header type (e.g. h3) at a specific breakpoint. Returns them as a map.
//
// Does not include the selector, only returns the properties. Each property is represented by a key-value pair. Returns
// the properties which are defined at that breakpoint, not the ones inherited from other breakpoints.
//
// todo add support for "flattened" output if only one style is requested, ie all effective styles from the breakpoint
// todo   sequence are aggregated and returned, up to the requested breakpoint.
@function header-styles ( $header-type, $breakpoint-name, $property-names: all, $typography-style-name: baseline ) {
    $property-names: if( $property-names == all, $_supported-header-property-names, $property-names );

    $styles-map: ();

    @each $property-name in $property-names {
        @if not contains( $_supported-header-property-names, $property-name ) {
            @error "Requested header property `#{$property-name}` is not supported in a header-styles() query";
        }

        $styles-map: _merge-header-style( $styles-map, $property-name, $header-type, $breakpoint-name, $typography-style-name );
    }

    @return $styles-map;
}

// Emits the styles for a given header type (e.g. h3). Does not include the selector, just the properties.
//
// The styles for the smallest breakpoint (base styles) are not wrapped in a `@include breakpoint` mixin, but the others
// are. If you only request a single breakpoint, however, the breakpoint wrapper is omitted (e.g. for
// `header-styles( h1, large )`).
//
// If you request a specific breakpoint, only the styles defined for that breakpoint are emitted. Styles which are
// inherited from smaller breakpoints do NOT show up.
//
// todo add support for "flattened" output if only one style is requested, ie all effective styles from the breakpoint
// todo   sequence are aggregated and returned, up to the requested breakpoint.
//
// Usage:
//
// - For default typography:
//
//   * @include header-styles( h4 );
//   * @include header-styles( h1, large );
//   * @include header-styles( h1, all, font-size line-height );
//
// - For a specific typographic style, e.g. one named "neutral":
//
//   * @include header-styles( h1, medium large, all, neutral );
//
@mixin header-styles ( $header-type, $breakpoint-names: all, $property-names: all, $typography-style-name: baseline ) {
    $breakpoint-names: if( $breakpoint-names == all, $breakpoint-names-all, $breakpoint-names );

    @each $breakpoint-name in $breakpoint-names {
        @if $breakpoint-name == $zero-breakpoint or length( $breakpoint-names ) == 1 {
            // Don't wrap the output in a breakpoint if we are dealing with the smallest breakpoint (base styles), or if
            // only one breakpoint is requested.
            @include map-emit( header-styles( $header-type, $breakpoint-name, $property-names, $typography-style-name ) );
        } @else {
            @include breakpoint( $breakpoint-name ) {
                @include map-emit( header-styles( $header-type, $breakpoint-name, $property-names, $typography-style-name ) );
            }
        }
    }
}

// Internal helper. Emits the header styles of a typographic style set in the global $typography-map. Covers the styles
// for all breakpoints. Output **includes** the selector (see below).
//
// Output can be limited to a specific sub-style (e.g h1) or a list of them (separated by spaces).
//
// Supported map properties: See the list `$_supported-header-property-names`.
//
// Default styles are generated for each missing property, rather than left out. That happens for the smallest
// breakpoint only, though - styles are not repeated for subsequent breakpoints. If you want to override the default
// styles, you need to do so with sufficient specificity.
@mixin _emit-header-styles ( $typography-style-name, $header-types: all ) {
    $header-types: if( $header-types == all, ( h1, h2, h3, h4, h5, h6 ), $header-types );

    // todo make sure em, strong, i, b work as intended
    @each $header-type in $header-types {
        #{$header-type}, .#{$header-type} {
            @include header-styles( $header-type, all, all, $typography-style-name );
        }
    }
}

// Internal helper. Emits the body styles. The font family, color and font size are always emitted.
//
// ATTN Body styles are expected to be the same across all breakpoints. Breakpoint keys in the style map are not
// supported.
@mixin _emit-body-styles ( $typography-style-name ) {
    $body-style-map: get-typography-style-set-property( $typography-style-name, body-styles );
    $default-font-stack: get-typography-style-set-property( $typography-style-name, default-font-stack );

    @include map-emit( $body-style-map );

    @if not map-has-key( $body-style-map, font-family ) {
        font-family: $default-font-stack;
    }

    @if not map-has-key( $body-style-map, color ) {
        color: $body-font-color;
    }

    @if not map-has-key( $body-style-map, font-size ) {
        // Default font size: should be equal to the (computed) font size defined on the root element
        font-size: 1rem;
    } @else {
        // Font size is taken from the map, without further action (handled by map-emit()). But we should issue a
        // warning if it is a relative font-size depending on the local context, like `%` or `em`. That does not ensure
        // a consistent font size anywhere the style is emitted. By contrast, `rem` does provide consistency and is ok.
        $warning-message-prefix: "The font-size style for default (body) text has been requested, as defined in the `#{$typography-style-name}` typography style. The font-size should have been defined in a unit which ensures consistent results wherever it is used - preferably `rem` or perhaps `px`. Instead, it has been set in `";
        $warning-message-suffix: "`. Consider fixing this in the `#{$typography-style-name}` typography style set.";

        $font-size: to-string( map-get( $body-style-map, font-size ) );
        @if ( str-slice( $font-size, -1 ) == "%" ) {
            @warn $warning-message-prefix + "%" + $warning-message-suffix;
        } @else if str-slice( $font-size, -2 ) == "em" and str-slice( $font-size, -3 ) != "rem" {
            @warn $warning-message-prefix + "em" + $warning-message-suffix;
        }
    }
}

// Emits a typographic style into the style sheet, either entirely or in selected parts. Requires the name of the
// typography style.
//
// Arguments:
//
// - $typography-style-name (required)
// - $sub-styles:
//   Values h1 ... h6, body, or all (default). You can pass a single identifier or a list of them, separated by spaces.
//
// ATTN The emitted styles include the selector and all breakpoints. Ie,
//
//     @include typography-styles ( neutral, h2 );
//
// is equivalent to
//
//     h2, .h2 {
//         ...properties of the "neutral" style
//
//         @include breakpoint( medium ) {
//             ...properties
//         }
//
//         ...other breakpoints
//     }
//
// todo option to limit output to single property? option to limit output to a single breakpoint? With and without aggregation? (probably no to all of them, this is a tool for generating the whole set.)
@mixin typography-styles ( $typography-style-name, $sub-styles: all ) {
    // todo inline the _emit-* mixins so that source maps point to the right place. (But still, it would only point here, which is hardly an improvement.)
    @each $sub-style in $sub-styles {
        @if $sub-style == all {
            @include _emit-body-styles( $typography-style-name );
            @include _emit-header-styles( $typography-style-name );
        } @else if $sub-style == body {
            @include _emit-body-styles( $typography-style-name );
        } @else {
            @include _emit-header-styles( $typography-style-name, $sub-style );
        }
    }
}

// Checks a typography style set for validity (currently limited to the header styles). Throws an error if an
// unsupported header style property is encountered.
//
// Only property names are tested, values are not examined for validity.
//
// To be used before a style set is added to the global $typography-map.
//
// NB This isn't a mixin really, it's a function. But Sass doesn't support functions without a return value, so a mixin
// construct it is. See https://stackoverflow.com/a/35828578/508355 and https://github.com/sass/sass/issues/1348
@mixin _verify-typography-header-styles ( $style-map, $style-map-name ) {
    $header-style-map: map-get-verbose( $style-map, header-styles, ( key-label: "`header-styles` property", map-label: "the \"#{$style-map-name}\" typography style set" ) );

    @each $breakpoint, $header-styles in $header-style-map {
        @each $header-name, $header-properties in $header-styles {
            @each $header-property-name, $header-property-value in $header-properties {
                @if not contains( $_supported-header-property-names, $header-property-name ) {
                    @error "Header property `#{$header-property-name}`, defined in typography style set \"#{$style-map-name}\", is not supported in a typography style set. (NB Consider adding support for it in the typography tools.)";
                }
            }
        }
    }
}

// Add a typography style set to the global $typography-map. Use this exclusively for the definition of typographic
// styles.
//
// NB This isn't a mixin really, it's a function. But Sass doesn't support functions without a return value, so a mixin
// construct it is. See https://stackoverflow.com/a/35828578/508355 and https://github.com/sass/sass/issues/1348
@mixin add-typography-style ( $name, $style-map ) {
    @include _verify-typography-header-styles( $style-map, $name );
    $typography-styles: map-add-property( $typography-styles, $name, $style-map ) !global;
}
