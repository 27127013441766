// Recipe page
body.recipe {

    .meta-container {
        @include clearfix;
    }

    .recipe-meta {
        font-weight: 300;
        color: get-palette-color( neutral );
    }

    @include breakpoint ( smedium ) {

        .recipe-meta {
            float: left;
        }

        .recipe-label + .recipe-source {
            float: right;
        }

    }

    .recipe-tagline {
        @include header-styles( h3 );
    }

    .recipe-preparation-data {
        font-style: italic;
    }

    .recipe-ingredients {
        margin: 2 * $global-margin 0;
        padding: 1.25 * $global-padding 2.5 * $global-padding 1.75 * $global-padding;

        border: rem-calc( 2 ) solid  get-palette-color( neutral, light, 5 );
        border-radius: 5 * $global-radius;

        h1 {
            @include header-styles( h4, all, all, recipe );
            text-align: center;
            margin-top: 0;
            margin-bottom: 2 * $header-margin-bottom;
        }

        p {
            font-size: rem-calc( 17 );
            line-height: 1.3;
            margin-bottom: 0.75 * $global-margin;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    .recipe-preparation {
        h1 {
            @include header-styles( h4, all, all, recipe );
        }
    }

}