// Stylesheet for colour palette overview, x-info/palette.html
$reference-logo-width: rem-calc( 1000 );
$palette-color-dot-size: rem-calc( 75 );

body.palette {
    @include typography-styles( neutral, h1 h2 h3 body );

    h2, h3 {
        margin-top: 2 * $global-margin;
    }

    .logo {
        width: $reference-logo-width;
        max-width: 75vw;
    }

    .base-color {
        width: 2 * $palette-color-dot-size;

        // Make it a circle
        border-radius: 50%;

        // Turning an empty div into a responsive square-ish shape, see
        // - https://spin.atomicobject.com/2015/07/14/css-responsive-square/
        // - https://css-tricks.com/forums/topic/making-a-responsive-css-square/
        // (The latter also offers a hack when content is added.)
        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .light > div, .dark > div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        width: $palette-color-dot-size;
        height: $palette-color-dot-size;
        border-radius: 50%;
    }

    .primary {
        //@include flattened-color-palette( "primary", get-base-color( $logo-blue, 0.75 ) );
        @include flattened-color-palette( "primary" );
    }

    .secondary {
        //@include flattened-color-palette( "secondary", get-base-color( $logo-orange ) );
        @include flattened-color-palette( "secondary" );
    }

    .neutral {
        //@include flattened-color-palette( "neutral", get-base-color( $neutral-color ) );
        @include flattened-color-palette( "neutral" );
    }

    .type-sample {
        border: rem-calc( 1 ) dotted grey;
        padding: 0 $global-padding;

        &.baseline-typography {
            @extend .baseline-typography;
        }

        &.book-typography {
            @extend .book-typography;
        }

        &.film-typography {
            @extend .film-typography;
        }

        &.notice-typography {
            @extend .notice-typography;
        }

        &.neutral-typography {
            @extend .neutral-typography;
        }

        &.main-menu-typography {
            @extend .main-menu-typography;
        }

        &.off-canvas-menu-typography {
            @extend .off-canvas-menu-typography;
        }
    }

}
