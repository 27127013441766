// Page component: flexible content

// Removing margin-top at the top of the flexible-content block, or another nested sub block of the same structure (e.g.
// books)
//
// We don't want a margin-top to leak out of the flexible-content block, nor do we want a margin-top to create empty
// space at the top inside the block.
//
// Unfortunately, we can't just target the first child element. That first child element might be a floating sub
// block (e.g. an image block or a gallery), at least upwards of a certain breakpoint. A margin-top on that floating
// element must remain untouched. Its purpose is to better align the floating element with the text beside it, not
// to create empty space above it.
//
// Instead, we need to target the _next_ child element in that case, but only if it rises up to the top of the
// flexible content (ie, it doesn't clear the float and stay below).
//
// And of course, if there isn't any floating block at the top, we must target the very first child element and
// remove the margin-top.
//
// We achieve that complicated dance with flag classes on the elements. Blocks which will float (if the screen is
// wide enough) get a .will-float class, blocks which don't rise to the top get a .will-clear class.
@mixin remove-topmost-margin-top {
    @include breakpoint( $media-block-float-switch-breakpoint-up ) {
        > .media-block.will-float:first-child + :not( .will-clear ) {
            margin-top: 0;
        }
    }

    @include breakpoint( $image-block-float-switch-breakpoint-up ) {
        > .image-block.will-float:first-child + :not( .will-clear ) {
            margin-top: 0;
        }
    }

    > :first-child {
        // Targeted element is neither .media-block nor .image-block, and in addition it is not .will-float
        // The first part of the selector could also be written as
        //     &:not( .media-block, .image-block )
        // but browser support is lacking.
        // See MDN :not() https://developer.mozilla.org/en-US/docs/Web/CSS/:not
        // and JSBin demo https://jsbin.com/xujabin/edit?html,css,output
        &:not( .media-block ):not( .image-block ), &:not( .will-float ) {
            margin-top: 0;
        }
    }
}

.flexible-content {

    @include clearfix;

    @include remove-topmost-margin-top;

    .will-clear {
        clear: both;
    }

    .section-heading {
        //@include header-styles( h2 );
    }

    .subheading {
        //@include header-styles( h3 );
    }

    .rich-text {
        // todo
        //column-width: rem-calc( 450 );
        //@include generate-from-setting( column-gap, $grid-column-gutter );
    }

    .inline-media {

        .media-info h1 {
            @include header-styles( h6 );
            text-align: inherit;
        }

    }

    .gallery {
        // todo
    }

    .book {
        @include remove-topmost-margin-top;

        // Don't include the clearfix here because margins of elements inside the book wrapper no longer collapse with
        // margins outside the book wrapper. Use `clear` instead, on the book wrapper itself (keeps it below preceding
        // stuff, no matter what) and on the element below the book wrapper (keeps it there, too).
        clear: both;

        + * {
            clear: both;
        }

        &:not( :last-child ) {
            margin-bottom: 3 * $global-margin;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .quote {
        // todo
    }

    .awards {
        @include plain-table();
    }

    .award-name {
        font-weight: bold;
    }

    .review {
        border: none;
        padding-left: 0;
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        text-align: inherit;
    }

}
