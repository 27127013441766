.system-notice {
    @include position-absolute-expand-to-context;

    main {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 95%;
        height: 100%;
    }

    .system-message {
        position: relative;
        top: -5%;

        padding: 2.25 * $global-padding 3 * $global-padding;
        border-radius: $global-radius;

        &.has-background {
            background-color: rgba( $white, 0.75 );

            &.is-dark {
                color: $white;
                background-color: rgba( $black, 0.75 );
            }
        }

        > :first-child {
            margin-top: 0;
        }

        > h1:first-child {
            @include breakpoint( medium ) {
                margin-top: -( $header-lineheight - 1 ) / 2 * header-font-size( h1, medium );
            }

            @include breakpoint( smedium down ) {
                margin-top: -( $header-lineheight - 1 ) / 2 * header-font-size( h1, small );
            }
        }

        > :last-child, .system-message-text > :last-child {
            margin-bottom: 0;
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
