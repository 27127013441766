// Ionicons icons - base styling
//
// Icon search: http://ionicons.com/
// Download of SVG version: https://rastasheep.github.io/ionicons-sprite/

.ion {
    display: inline-block;
    width: rem-calc( 32 );
    height: rem-calc( 32 );
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.ion-android-close {
    width: rem-calc( 17 );
    height: rem-calc( 17 );
    stroke-width: 17;
}
