// Configuration for the menu component
//
// Also see the menu configuration in config/_settings.scss! (In Section 23: Menu.)

$menu-color: $black;
$menu-color-dark: $black;
$menu-color-light: $black;

$dark-menu-link-hover-color: scale-color( $button-background, $lightness: 15% );
$light-menu-link-hover-color: $button-background-hover;  // Foundation default

$menu-solid-background-color: $white;
$menu-solid-background-color-lightened: lighten( $menu-solid-background-color, 5% );

$menu-active-font-weight: normal;

// Padding inserted to the right of the logo/home menu entry, to create extra space between it and the other menu
// entries.
$menu-home-extra-distance: 1.25 * second( $menu-items-padding );

// "More" button colors and padding (padding defines the size of the button)
$menu-more-button-color: get-palette-color( neutral, dark, 1 );
$menu-more-button-color-dark: $menu-more-button-color;
$menu-more-button-color-light: $menu-more-button-color;

$menu-more-button-border-color: get-palette-color( neutral, light, 2 );
$menu-more-button-border-color-dark: $menu-more-button-border-color;
$menu-more-button-border-color-light: $menu-more-button-border-color;

$menu-more-button-padding: 0.4rem 1rem;                                                                                 // stylelint-disable-line unit-allowed-list

// Vertical alignment of menu items.
//
// This should likely be set to `baseline` if you use text for the home menu item (ie, a type-based logo), and
// `center` if you use <picture>-based logo. (If need be, you can tweak the vertical position of the picture tag
// further, see below.)
$menu-vertical-item-alignment: baseline;

// Vertical offset of the <picture> logo, for fine-tuning its visual alignment with the other menu entries. Does not
// apply to a text-based logo/home menu entry.
$menu-logo-picture-vertical-offset: 0;

// Breakpoints

// Breakpoint for showing the full logo instead of the shortened ("iconic") one
$menu-breakpoint-show-full-logo: smedium;

// Breakpoint for showing all menu items (which also hides the "More" button)
$menu-breakpoint-show-all-items: 580;

// Breakpoint for showing menu items of priority 1 - 3
$menu-breakpoint-show-priority-3-items: ( 579 down );

// Breakpoint for showing menu items of priority 1 + 2
$menu-breakpoint-show-priority-2-items: ( 484 down );

// Breakpoint for showing menu items of priority 1 only
$menu-breakpoint-show-priority-1-items: ( 419 down );
