// Main logo, iconic logo
$logo-aspect-ratio: 10.4479;
$iconic-logo-aspect-ratio: 1.1351;

$footer-logo-height: 17;

#header {

    @include breakpoint( msmall down ) {
        nav .home {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .logo {
        // Iconic logo on smaller screens and as a fallback
        @include dims-from-height-and-ratio( 24, $iconic-logo-aspect-ratio );
        min-width: rem-calc( 28 );

        // Full logo
        @include breakpoint( smedium up ) {
            @include dims-from-width-and-ratio( 250, $logo-aspect-ratio );
            min-width: rem-calc( 200 );
        }
    }
}

#footer {
    padding-top: $global-padding;
    padding-bottom: $global-padding;

    .logo {
        @include dims-from-height-and-ratio( $footer-logo-height, $logo-aspect-ratio );
        min-width: rem-calc( 100 );
    }

    .iconic-logo {
        @include dims-from-height-and-ratio( $footer-logo-height, $iconic-logo-aspect-ratio );
        min-width: rem-calc( 20 );
    }
}
