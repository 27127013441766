.hero-image {
    margin-bottom: $global-margin;

    @include breakpoint( $hero-breakpoint-small-screen ) {
        margin-top: 0.5 * $global-margin;
        margin-bottom: 0.5 * $global-margin;

        &.fixed-aspect-ratio .image-wrapper {
            padding-bottom: $hero-small-screen-aspect-ratio !important;
        }
    }

    &.below-heading {
        margin-bottom: 2.5 * $global-margin;

        @include breakpoint( small down ) {

            &:not( .fixed-aspect-ratio ) {
                // Calculated so that the margin-bottom equals the default 2.5rem at the moment of transition. The
                // percentage is based on the width of the container block, #main.
                margin-bottom: 8.316%;
            }

        }
    }
}
