// Video player for Vimeo
.video-player-proxy.responsive {
    position: relative;
    // Acts as a persistent placeholder for the video iframe, in particular as the iframe is moved around during
    // animation. This element, by contrast, remains im place.
    //
    // Matches the aspect ratio of Vimeo videos,
    // see https://stackoverflow.com/a/42910047/508355
    // Override on the element for non-standard ratios
    padding-bottom: 56.25%;

    // The padding-bottom might be 1px too large for videos with custom aspect ratios, due to rounding errors (not an
    // issue with the default aspect ratio). The iframe is stretched too much by that 1px, and the page background
    // shines through. `overflow: hidden;` is the best way to fix it, but it also cuts off any box-shadow (which is
    // drawn around the iframe, while the background shines through inside the iframe).
    //
    // So when we have a box-shadow, we allow the overflow and add a solid, black background to the iframe instead,
    // which matches the black letterbox around custom-sized videos.
    overflow: hidden;

    @at-root .has-shadow & {
        overflow: visible;
    }

    .video-player-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        iframe, object, embed {
            width: 100%;
            height: 100%;

            @at-root .has-shadow & {
                @include default-shadow;
                background-color: black;
            }
        }

        .glass {
            display: none;
            opacity: 0;     // initially

            background-color: $white;

            // We expand the "glass" element to make sure it covers areas beyond the screen which might be exposed when
            // the user attempts to scroll, or by the rubber-band effect in mobile browsers, or when a user swipe
            // minimizes the UI.
            //
            // The "glass" is expanded massively, just to be on the safe side. The huge size does not cause any issues,
            // such as enlarging the scrollable area, because the "glass" element is set to position: fixed.
            position: fixed;
            top: rem-calc( -1000 );
            left: rem-calc( -1000 );
            bottom: rem-calc( -1000 );
            right: rem-calc( -1000 );

            z-index: 0;
        }

        .close-button {
            display: none;
            padding: 0 rem-calc( 8 );
            z-index: 3;

            .ion-android-close {
                width: rem-calc( 17 );
                height: rem-calc( 17 );
                stroke-width: 17;
            }
        }

        &.video-overlay {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            // Menu z-index is 3. Set this one here to less (2) to keep the menu visible and actionable, or to more (4) to
            // obscure it.
            z-index: 4;

            .glass {
                display: block;
            }

            .close-button {
                display: block;
            }

            // Final state, after animation (or without one). It centers the iframe even when the window size changes.
            //
            // This requires removing all dynamic styles, set on the iframe by the animation, once the animation is
            // over.
            iframe {
                position: absolute;
                z-index: 2;

                // Centering the element
                // See https://www.smashingmagazine.com/2013/08/absolute-horizontal-vertical-centering-css/
                margin: auto;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                // Leaving this in place to prevent white flashes in iOS, even though it didn't really help here. (A bit
                // of a cargo-cult-style safeguard, admittedly.) White flashes stopped being an issue when
                // `transform: scale` was ditched.
                //
                // See _carousel.scss for more info and a discussion of white flashes on iOS.
                backface-visibility: hidden;
                transform-style: preserve-3d;
            }

            &.in-transition {
                .glass {
                    will-change: opacity;
                }

                .close-button {
                    display: none;
                }

                iframe {
                    will-change: width, height, transform;
                    box-shadow: none;
                    background-color: transparent;
                }
            }
        }
    }
}
