// About page
.privacy {

    .language-switch {

        font-size: $small-font-size;
        margin-top: $global-margin;

        .button {
            vertical-align: 20%;
            margin-left: 0.25 * $global-margin;
        }

    }

}
