// Various Mixins
//
// ATTN Many of these mixins depend on Sass functions defined in Foundation, such as rem-calc(). Load foundation first.

// Mixins and functions
@mixin dims-from-width-and-ratio ( $width, $aspect-ratio ) {
    width: rem-calc( $width );
    height: rem-calc( $width / $aspect-ratio );
}

@mixin dims-from-height-and-ratio ( $height, $aspect-ratio ) {
    width: rem-calc( $height * $aspect-ratio );
    height: rem-calc( $height );
}

@mixin position-absolute-horizontally-centered ( $width, $mode: "full" ) {
    @if $mode == "full" {
        position: absolute;
        left: 50%;
    }
    width: rem-calc( $width );
    margin-left: rem-calc( - $width / 2 );
}

@mixin position-absolute-expand-to-context () {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin frosted-glass ( $intensity, $color-scheme: "dark", $transition-duration: 0, $property: background-color ) {
    // $color-scheme: "light"
    $color: rgba( 255, 255, 255, $intensity );

    @if $color-scheme == "dark" {
        $color: rgba( 0, 0, 0, $intensity );
    }

    @if $transition-duration != 0 {
        transition: opacity $transition-duration ease-out;
    }

    @if $property == box-shadow {
        box-shadow: inset 0 0 0 rem-calc( 5000 ) $color;
    } @else {
        #{$property}: $color;
    }
    opacity: 1;
}

@mixin default-shadow () {
    box-shadow: rem-calc( 3 ) rem-calc( 3 ) rem-calc( 6 ) rgba( 0, 0, 0, 0.3 );
}

@mixin plain-table () {

    width: auto;

    &, tbody {
        border: none;
    }

    tbody tr {
        background-color: transparent;
    }

    td {
        padding-left: 0;
        vertical-align: text-top;
    }
}

// Controls the colours of a `.flexible-badge`
//
// Creates the colours for the default (solid) style, the `.translucent` style and the `.hollow` style. If you omit the
// `$hover-color` or set it to null, hover and focus styles will not be created.
//
// `$translucent-opacity` sets the background opacity of the `.translucent` style (in addition to a small dose of
// overall opacity, which is hard-coded and also affects the label text).
//
// NB `.flexible-badge` is a button or label, depending on whether or not it acts as a link. It is used as an overlay on
// other objects, such as images.
@mixin flexible-badge-colors ( $base-color, $hover-color: null, $font-base-color: $white, $translucent-opacity: 0.5 ) {
    background-color: $base-color;
    border-color: $base-color;
    color: $font-base-color;

    @if $hover-color {
        &:hover, &:focus {
            background-color: $hover-color;
            border-color: $hover-color;
        }
    }

    &.translucent {
        opacity: 0.8;
        background-color: rgba( $base-color, $translucent-opacity );
        border-color: transparent;

        @if $hover-color {
            &:hover, &:focus {
                background-color: rgba( $hover-color, $translucent-opacity );
                border-color: transparent;
            }
        }
    }

    &.hollow {
        background: none;
        color: $base-color;

        border-width: $button-hollow-border-width;
        border-style: solid;

        @if $hover-color {
            &:hover, &:focus {
                color: $hover-color;
            }
        }
    }
}

// Controls the position of a `.flexible-badge`. Defines the position for .top, .left, .bottom, .right classes and the
// .further modifier class.
//
// The distance to the edge of the container is controlled by the badge margin.
//
// Button badges (as opposed to label badges) must be repositioned horizontally, or else the distance to the horizontal
// edge seems too small even though the math is correct. Hence the separate $badge-margin-button-horizontal setting.
//
// The $further-factor controls how much further the badge is pushed inward if the .further modifier class is applied.
//
// NB `.flexible-badge` is a button or label, depending on whether or not it acts as a link. It is used as an overlay on
// other objects, such as images.
@mixin flexible-badge-margins ( $badge-margin: 4.4%, $badge-margin-button-horizontal: 5.5%, $further-factor: 2 ) {
    $positions: top bottom left right;

    @each $position in $positions {
        &.#{$position} {
            #{$position}: 0;
            margin-#{$position}: $badge-margin;

            &.further {
                margin-#{$position}: $further-factor * $badge-margin;
            }

            @if $position == "left" or $position == "right" {
                &.button {
                    margin-#{$position}: $badge-margin-button-horizontal;

                    &.further {
                        margin-#{$position}: $further-factor * $badge-margin-button-horizontal;
                    }
                }
            }
        }
    }
}

// Generate a property from a setting. The setting can be a map with breakpoints as keys, or a simple value, or a
// settings variable which can contain either.
@mixin generate-from-setting ( $property, $setting ) {
    @if type-of( $setting ) == "map" {
        @each $breakpoint, $value in $setting {
            @include breakpoint( $breakpoint ) {
                #{$property}: $value;
            }
        }
    } @else {
        #{$property}: $setting;
    }
}
