// Flexible image component
.image-block {

    box-sizing: content-box;
    position: relative;

    // Styles for small, msmall, and for .text-full (expand to full width) at any size
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    margin-top: $image-block-top-spacing;
    margin-bottom: $image-block-bottom-spacing;

    &.text-full {
        margin-top: $image-block-full-width-vertical-spacing;
        margin-bottom: $image-block-full-width-vertical-spacing;
    }

    &.text-center {
        margin-top: $image-block-centered-vertical-spacing;
        margin-bottom: $image-block-centered-vertical-spacing;
    }

    &.text-full, &.text-center {
        // Don't create vertical space when the full-width or centered image is at the top or bottom of a block
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .image-wrapper {
        display: inline-block;
        position: relative;

        margin: 0;
        padding: 0;
        border: none;
    }

    .image-wrapper, img {
        box-sizing: content-box;

        // Limit image to original size by default, no upscaling
        max-width: 100%;

        &.global-radius {
            border-radius: $global-radius;
        }
    }

    &.has-border img {
        border: rem-calc( 1 ) solid $dark-gray;
    }

    &.has-shadow img {
        @include default-shadow;
    }

    &.scale-up {
        .image-wrapper, img {
            box-sizing: border-box;
            width: 100%;
            max-width: none;
        }
    }

    &.fit-height-to-window img {
        max-height: 95vh;
    }

    &.fixed-aspect-ratio {
        .image-wrapper {
            width: 100%;

            // NB We need the nesting, ie the elevated specificity, to override conflicting
            // .image-height-controls-size styles
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        &.fit-height-to-window img {
            // With a fixed aspect ratio driven by a percentage padding, max height can't be set on the image
            // wrapper without lots of layout problems. Setting it on the outer block wrapper or the image itself
            // doesn't work, either. It would have to be managed with a JS helper. Instead, we disable the setting
            // for the sake of simplicity.
            max-height: none;
        }
    }

    &.image-height-controls-size {
        // display: table shrinks the wrapper to the size of the image
        display: table;
        width: auto;

        .image-wrapper, img {
            // Image width is driven by image height and intrinsic aspect ratio now
            width: auto;

            // The image is never allowed to be wider than the content area, so we set the max-width to 100%
            max-width: 100%;
        }

        .caption {
            // Makes the caption shrink to the size of the image, along with display: table on the wrapper.
            //
            // This is needed when the wrapper isn't set to an explicit width (which also limits the caption). With
            // width: auto on the wrapper, it would expand to accommodate the caption, and the caption text would
            // not wrap. This fixes it.
            //
            // See https://stackoverflow.com/a/19458847/508355
            display: table-caption;

            &, &.below {
                caption-side: bottom;
            }

            &.above {
                caption-side: top;
            }
        }
    }

    .caption {
        font-size: 80%;

        &.below {
            // Keep some distance from the image
            margin-top: $image-to-caption-spacing;
        }

        &.above {
            margin-top: 0;
            margin-bottom: $image-to-caption-spacing;
        }

        &.overlay-top, &.overlay-bottom {
            position: absolute;
            left: 0;
            right: 0;
            padding: $caption-overlay-vertical-padding $caption-overlay-horizontal-padding;

            &.dark-image {
                &, & > * {                                                              // stylelint-disable-line scss/selector-no-redundant-nesting-selector
                    color: get-palette-color( neutral, light, 3 );
                }

                @include frosted-glass( $caption-overlay-opacity-dark, "dark" );
            }

            &.light-image {
                @include frosted-glass( $caption-overlay-opacity-light, "light" );
            }

        }

        &.overlay-top {
            top: 0;
        }

        &.overlay-bottom {
            bottom: 0;
        }

        // If elements are nested inside the caption (usually p), don't let the margin-bottom of the last one
        // enlarge the margin below the caption. Likewise for overlays, where it would enlarge the background.
        &.below, &.overlay-top, &.overlay-bottom {
            & > :last-child {                                                          // stylelint-disable-line scss/selector-no-redundant-nesting-selector
                margin-bottom: 0;
            }
        }

        // Similarly for a caption on top (e.g. for a nested h1)
        &.above, &.overlay-top, &.overlay-bottom {
            & > :first-child {                                                          // stylelint-disable-line scss/selector-no-redundant-nesting-selector
                margin-top: 0;
            }
        }

    }

    .flexible-badge {
        position: absolute;
        margin: 0;

        &.button {
            @include flexible-badge-colors(
                get-palette-color( primary, light, 1 ),
                get-palette-color( primary )
            );

            &.light {
                @include flexible-badge-colors(
                    get-palette-color( primary, light, 4 ),
                    get-palette-color( primary, light, 5 ),
                    $black
                );
            }
        }

        &.label {
            @include flexible-badge-colors(
                get-palette-color( primary, dark, 4 )
            );

            padding: 0.5 * $global-padding;

            &.light {
                @include flexible-badge-colors(
                    get-palette-color( primary, light, 5 ),
                    null,
                    $black
                );
            }
        }

        @include flexible-badge-margins();
    }

    @include breakpoint( $image-block-float-switch-breakpoint-down ) {
        // We need to override any percentage width defined in the backend, which is set as an element style.
        // Likewise for min-width. We need !important for that.
        //
        // NB The defined percentage is not meant to be applied to small screens - we go full width. And min-width
        // must not be allowed to mess with small screens (image might become too wide for the screen).
        width: 100% !important;
        min-width: 0 !important;
    }

    @include breakpoint( $image-block-float-switch-breakpoint-up ) {
        width: auto;

        &.with-sizes-block {
            // Percentage widths are supposed to relate to the entire image block, not just the image itself. It is set
            // on the wrapper, so include padding in the width.
            box-sizing: border-box;
        }

        &.text-left {
            float: left;
            padding-right: $image-block-side-spacing;

            // When the caption is set to display: table-caption, the padding of the wrapper does not affect it.
            // We need to set it on the caption, too.
            &.image-height-controls-size .caption {
                &.above, &.below {
                    padding-right: $image-block-side-spacing;
                }
            }

            // The absolutely positioned caption overlay would extend to the padding edge, so we push it back in
            // with its own margin. (Padding wouldn't work, it extends the semi-transparent background.)
            .overlay-top, .overlay-bottom {
                margin-right: $image-block-side-spacing;
            }
        }

        &.text-right {
            float: right;
            padding-left: $image-block-side-spacing;

            // See above, text-left.
            &.image-height-controls-size .caption {
                &.above, &.below {
                    padding-left: $image-block-side-spacing;
                }
            }

            // See above, text-left.
            .overlay-top, .overlay-bottom {
                margin-left: $image-block-side-spacing;
            }
        }
    }
}

