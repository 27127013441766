// Mixins
//
// Content area padding: The mixin makes it available throughout the application, with the option to modify it for
// specific sections (positive surplus padding: indent, negative surplus padding: targeted section sticks out of the
// content area).
@mixin content-area-padding ( $left-surplus-padding: 0, $right-surplus-padding: 0 ) {
    padding-left: 2 * $global-padding + $left-surplus-padding;
    padding-right: 2 * $global-padding + $right-surplus-padding;

    @include breakpoint( small only ) {
        padding-left: $global-padding + $left-surplus-padding;
        padding-right: $global-padding + $right-surplus-padding;
    }
}


// Box-sizing default
//
// Can be overridden in components.
// See https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}


// General body settings, width category classes

body {
    background-color: $white;

    main, #footer, .primary-nav .nav-menu {
        @include content-area-padding;
        margin-left: auto;
        margin-right: auto;
    }

    &.page-width-fixed-narrow {

        main, #footer, .primary-nav .nav-menu {
            max-width: rem-calc( 800 );
        }
    }

    &.page-width-fixed-wide {

        main, #footer, .primary-nav .nav-menu {
            max-width: rem-calc( 1440 );
        }

    }
}


// Footer
#footer {
    display: flex;
    align-items: baseline;

    &.with-copyright {
        justify-content: space-between;
    }

    &.no-copyright {
        // Makes sure the contact button and privacy notice link are still right-aligned when the (left-aligned)
        // copyright notice is absent
        justify-content: flex-end;
    }

    .copyright {
        font-size: $copyright-font-size;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .button {
        border-radius: $global-radius;
    }

    .contact + .legal {
        margin-top: $global-margin;
    }

    .email {
        margin: 0;
        white-space: nowrap;
        border-color: $footer-text-color;
    }

    .privacy-notice {
        // Font size should be small-ish and match the font size used in the email button (defined in Foundation's
        // $button-sizes)
        font-size: $footer-legal-font-size;
        white-space: nowrap;
    }

    .copyright, .copyright a, .info, .info a {
        color: $footer-text-color;
    }
}
