// Configuration for the image block component

$image-block-float-switch-breakpoint-up: smedium;
$image-block-float-switch-breakpoint-down: msmall down;

$image-block-top-spacing: 0.25 * $global-margin;                // Compromise, works for approximate alignment next to headlines or paragraph text.
$image-block-bottom-spacing: 2 * $global-margin;
$image-block-full-width-vertical-spacing: 3 * $global-margin;   // Space above and below the block when its position is set to "full"
$image-block-centered-vertical-spacing: 2.5 * $global-margin;   // Space above and below the block when its position is set to "center"
$image-block-side-spacing: 3 * $global-padding;                 // when floating left or right
$image-to-caption-spacing: $global-margin;

$caption-overlay-vertical-padding: 0.5 * $global-padding;       // when the caption is an overlay
$caption-overlay-horizontal-padding: $global-padding;
$caption-overlay-opacity-dark: 0.85;
$caption-overlay-opacity-light: 0.8;

