// ----------
// Colour utilities
// ----------

// Assumes a given sample colour is the result of a base colour, at a given opacity, being placed before a background.
// Reconstructs the base colour from the sample and returns it.
//
// Inverse function of flatten-color().
@function get-base-color ( $rgb-sample, $sample-opacity: 1, $rgb-background: rgb( 255, 255, 255 ) ) {
    @return rgb(
        get-channel-base( red( $rgb-sample ), $sample-opacity, red( $rgb-background ) ),
        get-channel-base( green( $rgb-sample ), $sample-opacity, green( $rgb-background ) ),
        get-channel-base( blue( $rgb-sample ), $sample-opacity, blue( $rgb-background ) )
    );
}

// Assumes a foreground colour at a given opacity, against a given background. Returns the resulting colour, with
// foreground an background flattened into a single, opaque RGB value.
//
// If the background colour is omitted, a white background is assumed. At opacity 1, the foreground colour is returned
// unaltered.
@function flatten-color ( $color, $opacity: 1, $background-color: rgb( 255, 255, 255 ) ) {
    @return rgb(
        flatten-channel( red( $color ), $opacity, red( $background-color ) ),
        flatten-channel( green( $color ), $opacity, green( $background-color ) ),
        flatten-channel( blue( $color ), $opacity, blue( $background-color ) )
    );
}


// ----------
// Palette utilities
// ----------

// Creates a colour palette map, with base colours as well as light and dark colour variations, and returns it. Expects
// a map of base colours as its argument, each defined by its name (as key) and base colour value.
//
// Colour names are arbitrary and can be chosen freely. They only matter when retrieving a colour with get-palette-color().
// The number of base colours is not limited.
//
// Example call:
//
//     $my-palette: get-color-palette( (
//         primary:   $some-base-color,
//         secondary: $other-base-colour,
//         accent:    $another-color,
//         greyscale: $medium-gray-base
//     ) );
//
// Note the double parentheses - the outer ones for the function call, the inner ones for the map.
@function get-color-palette ( $base-color-map ) {
    $palette: ();

    @each $label, $base-color in $base-color-map {                              // sass-lint:disable-block indentation
        $sub-palette: map-collect(
            ( base: $base-color ),
            get-palette-color-variations( "light", $base-color ),
            get-palette-color-variations( "dark", $base-color )
        );

        $palette: map-merge( $palette, ( #{$label}: $sub-palette ) );
    }

    @return $palette;
}

// Returns a colour from the palette.
//
// Colours are retrieved by name, variation (light or dark), and variation intensity (scale 1-5). Valid colour names are
// the ones which have been defined with get-color-palette().
//
// So, assuming a colour named "primary" has been defined,
//
// - get-palette-color( primary ) returns the main colour
// - get-palette-color( primary, dark ) returns the default dark variation (intensity 3)
// - get-palette-color( primary, light, 2 ) returns the dark variation of intensity 2
@function get-palette-color ( $name, $variation-type: "base", $variation-intensity: 3 ) {
    $named-palette: map-get-verbose( $color-palette, $name, ( key-label: "color name", map-label: "$color-palette" ) );
    $variation: map-get-verbose( $named-palette, $variation-type, ( key-label: "variation name", map-label: "$color-palette.#{$name}" ) );

    @if $variation-type == "base" {
        @return $variation;
    } @else {
        @return map-get-verbose( $variation, $variation-intensity, ( key-label: "variation intensity", map-label: "$color-palette.#{$name}.#{$variation-type}" ) );
    }
}

// ----------
// Specific utilities for the palette.html overview page.
// ----------

@mixin flattened-palette-variations ( $label, $type: "light" ) {

    .var-90 {
        background-color: get-palette-color( #{$label}, #{$type}, 1 );
    }

    .var-75 {
        background-color: get-palette-color( #{$label}, #{$type}, 2 );
    }

    .var-50 {
        background-color: get-palette-color( #{$label}, #{$type}, 3 );
    }

    .var-25 {
        background-color: get-palette-color( #{$label}, #{$type}, 4 );
    }

    .var-10 {
        background-color: get-palette-color( #{$label}, #{$type}, 5 );
    }

}

@mixin flattened-color-palette ( $label ) {
    .base-color {
        background-color: get-palette-color( "#{$label}" );
    }

    .light {
        @include flattened-palette-variations( "#{$label}", "light" );
    }

    .dark {
        @include flattened-palette-variations( "#{$label}", "dark" );
    }
}

// ----------
// Internal helper functions
// ----------

// For the relation of a transparent RGBA colour plus background colour to a flattened RGB result,
// see https://stackoverflow.com/a/15898886/508355
@function get-channel-base ( $sample, $sample-alpha, $background ) {
    @if ( $sample-alpha == 0 ) {
        @return $sample;
    } @else {
        @return ( ( $sample - $background ) / $sample-alpha ) + $background;
    }
}

@function flatten-channel ( $channel-color, $opacity, $background-channel-color ) {
    @return ( $channel-color * $opacity ) + ( $background-channel-color * ( 1 - $opacity ) );
}

@function get-palette-color-variations ( $type, $base-color ) {
    $background-color: if( $type == "dark", rgb( 0, 0, 0 ), rgb( 255, 255, 255 ) );

    @return (                                                                   // sass-lint:disable-block indentation
        #{$type}: (
            1: flatten-color( $base-color, 0.9, $background-color ),
            2: flatten-color( $base-color, 0.75, $background-color ),
            3: flatten-color( $base-color, 0.5, $background-color ),
            4: flatten-color( $base-color, 0.25, $background-color ),
            5: flatten-color( $base-color, 0.1, $background-color )
        )
    );
}
