// Server info overlay
//
// NB The overlay does not appear in the live environment
html.show-server-info:not( .env-live ) {

    body::before {
        position: fixed;
        bottom: rem-calc( 1 );
        right: rem-calc( 1 );
        z-index: 100;
        padding: 1ch;                                                           // stylelint-disable-line unit-allowed-list
        font: normal 0.7rem "Lucida Grande", sans-serif;
        border-radius: $global-radius;
    }

    &.env-staging {
        body::before {
            content: "STAGE";
            // background #59caff @ opacity 0.67:
            background: rgba( 89, 202, 255, 0.67 );
            color: rgba( 255, 255, 255, 0.75 );
        }
    }

    &.env-staging-1u1 {
        body::before {
            content: "STAGE 1&1";
            // background #59caff @ opacity 0.67:
            background: rgba( 89, 202, 255, 0.67 );
            color: rgba( 255, 255, 255, 0.75 );
        }
    }

    &.env-staging-zw {
        body::before {
            content: "STAGE ZW";
            // background #99c2ff @ opacity 0.67:
            background: rgba( 153, 194, 255, 0.67 );
            color: rgba( 255, 255, 255, 0.75 );
        }
    }

    &.env-local-dev {
        body::before {
            content: "local-dev";
            // background #cc5643 @ opacity 0.67:
            background: rgba( 204, 86, 67, 0.67 );
            color: rgba( 255, 255, 255, 0.75 );
        }
    }

    &.env-local-dev-accelerated {
        body::before {
            content: "local-dev-acc";
            // background #8b5eff @ opacity 0.67:
            background: rgba( 139, 94, 255, 0.67 );
            color: rgba( 255, 255, 255, 0.75 );
        }
    }

    &.env-local-staging {
        body::before {
            content: "local-staging";
            // background #84fcff @ opacity 0.67:
            // color #ffffff @ opacity 0.75
            background: rgba( 132, 252, 255, 0.67 );
            color: rgba( 255, 255, 255, 0.75 );
        }
    }

}

// Breakpoint info overlay
//
// NB The overlay does not appear in the live environment
html.show-breakpoint-info:not( .env-live ) {

    // Shift the position upwards if the server info overlay is also present
    &.show-server-info::before {
        bottom: rem-calc( 26 );
    }

    &::before {
        position: fixed;
        bottom: rem-calc( 1 );
        right: rem-calc( 1 );
        z-index: 100;
        padding: 1ch;                                                           // stylelint-disable-line unit-allowed-list
        background: rgba( 0, 0, 0, 0.67 );
        color: rgba( 255, 255, 255, 0.75 );
        font: normal 0.7rem "Lucida Grande", sans-serif;
        border-radius: $global-radius;

        @include breakpoint( small ) {
            content: "small";
        }

        @include breakpoint( msmall ) {
            content: "msmall";
        }

        @include breakpoint( smedium ) {
            content: "smedium";
        }

        @include breakpoint( medium ) {
            content: "medium";
        }

        @include breakpoint( large ) {
            content: "large";
        }

        @include breakpoint( xlarge ) {
            content: "xlarge";
        }

        @include breakpoint( huge ) {
            content: "huge";
        }

        @include breakpoint( xhuge ) {
            content: "xhuge";
        }
    }

}