// About page
.about {

    // todo remove again, or at least review it - this is just a hack
    #main article {
        display: flex;
        flex-direction: column;
        align-items: center;

        #contact {
            display: none;
        }
    }

    .contact-methods {
        @include plain-table();

        td {
            padding-top: rem-calc( 4 );
            padding-bottom: 0;
        }

        .type {
            padding-right: rem-calc( 20 );
        }
    }

}
