//  Navigation menu: permanent menu bar and mobile off-canvas menu
//
// Choose one of three base styles for the navigation by applying the corresponding class to the #header element:
//
// - .glass:  semi-transparent menu overlay
// - .solid:  solid menu bar with small-ish, separated menu items
// - .blocks: solid menu bar with menu items forming a contiguous series of blocks
//
// Design config settings for this component are stored in config/_menu.


// Mixins
// NB These mixins are kept here because they are one-offs for the navigation, used as a workaround for duplicated code.
// See https://stackoverflow.com/q/14840918/508355 for the reasons a placeholder and @extend can't be used here.

// Mixin aligning the text of the first menu item to the left edge of the content area, and the text of the last one to
// the right edge. The padding around the text does not extend outside of these limits either (the padding is removed at
// the outer edge).
@mixin align-menu-text-to-edges () {

    // Targeting the "About" menu entry here, aligning it with the right content edge. (Last child is "More").
    li:nth-last-child( 2 ) {
        a, div {
            padding-right: 0;
        }
    }

    // Aligning the home logo to the left content edge
    li.home, li.home a, li.home div {
        text-align: left;
        padding-left: 0;
    }

}

// Mixin aligning the **box** of the first menu item to the left edge of the content area, and the box of the last one
// to the right edge. The padding around the text is not removed but stays within the outer edge.
@mixin align-menu-boxes-to-edges () {

    // Targeting the "About" menu entry here. (Last child is "More"). We are explicitly restoring the default padding in
    // case are overriding the align-menu-to-edges() mixin here.
    li:nth-last-child( 2 ) {
        a, div {
            padding-right: second( $menu-items-padding );
        }
    }

    // We also need to make sure the "more" button gets shifted as if it had a right padding
    .more {
        margin-right: second( $menu-items-padding );
    }

    // Restoring the default padding for the home logo (left content edge)
    li.home {
        a, div {
            text-align: left;
            padding-left: second( $menu-items-padding );
        }
    }

}

// Mixin aligning the text of the first menu item to the left edge of the content area, and the text of the last one to
// the right edge. The padding around the text is not removed, though, and does extend beyond the outer edge.
@mixin align-menu-text-to-edges-while-padding-sticks-out () {

    // Widening the primary-nav container to accommodate the padding of the first and last menu item
    @include content-area-padding( - second( $menu-items-padding ), - second( $menu-items-padding ) );

    @include align-menu-boxes-to-edges;
}


// Primary navigation: design choices (.glass, .solid, .blocks)
#header {
    position: relative;
    z-index: 3;

    // Frosted glass effect
    &.glass {
        &::before {
            @include position-absolute-expand-to-context;
            z-index: 1;

            content: " ";
            opacity: 0;

            @at-root body.is-light & {
                @include frosted-glass( 0.1, "light", $transition-duration: 5s );
            }

            @at-root body.is-dark & {
                @include frosted-glass( 0.25, "dark", $transition-duration: 5s );
            }

            @at-root body:not( .full-screen ) & {
                @include frosted-glass( 0.02, "dark" );
            }
        }
    }

    .nav-menu li {
        position: relative;
        z-index: 2;
    }

    // Alternative, solid header style
    &.solid {
        background-color: $menu-solid-background-color;

        .nav-menu {
            @at-root body:not( .full-screen ) & {
                @include align-menu-text-to-edges-while-padding-sticks-out;
            }

            // Exact same as above, but applied to body.full-screen now, for breakpoints small to medium
            @include breakpoint( medium down ) {
                @include align-menu-text-to-edges-while-padding-sticks-out;
            }
        }

        li {
            background-color: $menu-solid-background-color;

            &.active.highlight-active:not( .home ) {
                a, div {
                    border-radius: $global-radius;
                    background-color: $menu-solid-background-color-lightened;
                    color: $dark-menu-link-hover-color;

                    font-weight: $menu-active-font-weight;
                    border: none;
                }

                a:hover {
                    color: lighten( $dark-menu-link-hover-color, 5% );
                }
            }

            a:hover {
                border-radius: $global-radius;
                background-color: $menu-solid-background-color-lightened;
                color: $dark-menu-link-hover-color;
            }

            button:hover {
                color: $dark-menu-link-hover-color;
                border-color: $dark-menu-link-hover-color;
            }
        }
    }

    // Second solid header style, using large, continuous, visually separated blocks as menu items
    &.blocks {
        background-color: $menu-solid-background-color;

        .primary-nav {
            // Remove the vertical padding from nav wrapper in this case. The padding is added to the existing padding
            // of the `a` elements instead, so that the `a` blocks can expand to the edge of the nav bar. (Modify as
            // needed.)
            @include breakpoint ( medium ) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .nav-menu {
            @at-root body:not( .full-screen ) & {
                @include align-menu-text-to-edges-while-padding-sticks-out;
                // or, for other visual designs:
                //@include align-menu-boxes-to-edges;
            }

            // Exact same as above, but applied to body.full-screen now, for breakpoints small to medium
            @include breakpoint( medium down ) {
                @include align-menu-boxes-to-edges;
            }

            // Make `li` elements stretch vertically
            align-items: stretch;
        }

        li {
            // Make `li` elements expand to expand horizontally, gobbling up all available space between them
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;

            // Make sure that inner `a` elements are center-aligned vertically if they differ in height (can happen
            // because of the logo)
            display: flex;
            justify-content: center;
            align-items: center;

            border-left: rem-calc( 1 ) solid $menu-solid-background-color-lightened;

            &.home, &.more {
                border: none;
            }

            &.more {
                justify-content: flex-end;
            }

            a, div {
                width: 100%;

                // Vertical padding: apply the default padding and add the padding which is normally applied to the
                // nav element (because here, we want the `a` element to expand vertically, right up to the edges of
                // the nav).
                @include breakpoint ( medium ) {
                    padding-top: first( $menu-items-padding ) + first( $global-menu-padding );
                    padding-bottom: first( $menu-items-padding ) + first( $global-menu-padding );
                }
            }

            &.active.highlight-active:not( .home ) {
                background-color: $menu-solid-background-color-lightened;

                &, + li {
                    border-color: $menu-solid-background-color;
                }

                a, div {
                    font-weight: normal;
                    border-radius: 0;
                    border: none;
                }
            }

            // Text hover effect for dark menu backgrounds. Disable (= use Foundation default) for light backgrounds.
            a:hover, button:hover {
                color: $dark-menu-link-hover-color;
                border-color: $dark-menu-link-hover-color;
            }

            // Hover effect is applied to the `li`, not the `a`, so that it truly extends to the upper and lower edge of
            // the nav bar. The `a` block falls short if the `a` blocks are of (slightly) different heights.
            &:not( .more ):not( .active-self ):hover {
                background-color: $menu-solid-background-color-lightened;
                color: $dark-menu-link-hover-color;
            }
        }
    }

}

// Primary navigation: all other styles
.primary-nav {

    padding-top: 0;
    padding-bottom: 0;

    @include breakpoint ( medium ) {
        padding-top: first( $global-menu-padding );
        padding-bottom: first( $global-menu-padding );
    }

    li.active.highlight-active:not( .home ) {
        a, div {
            border-radius: $global-radius;
            border: rem-calc( 1 ) solid $light-gray;
        }
    }

    a, div {
        color: $menu-color;
    }

    @at-root body.is-light & {
        a, div {
            color: $menu-color-dark;
        }
    }

    @at-root body.is-dark & {
        a, div {
            color: $menu-color-light;
        }
    }

    .nav-menu {

        justify-content: space-between;
        align-items: $menu-vertical-item-alignment;
        text-align: center;

        // Replacing class menu:
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative;
        display: flex;

        li {
            margin-top: 0;
            margin-bottom: 0;

            white-space: nowrap;
        }

        a, button, div {
            display: block;
            padding: $menu-items-padding;
            margin-bottom: 0;

            line-height: 1;
            text-decoration: none;
            white-space: nowrap;
        }

        li.home {
            // Inserting additional space between the home logo and the other menu items
            padding-right: $menu-home-extra-distance;

            // Setting for a <picture>-based logo
            picture {
                // Tweaking the vertical logo position for proper alignment with the menu text
                transform: translateY( $menu-logo-picture-vertical-offset );
            }

            // Setting for a text-based logo, in a long ("full") and short ("iconic") version, each inside a <span>
            .full-logo {
                display: none;
            }

            @include breakpoint( $menu-breakpoint-show-full-logo ) {
                .full-logo {
                    display: inline;
                }

                .iconic-logo {
                    display: none;
                }
            }
        }

        @at-root body:not( .full-screen ) & {
            @include align-menu-text-to-edges-while-padding-sticks-out;
        }

        // Exact same as above, but applied to body.full-screen now, for breakpoints small to medium
        @include breakpoint( medium down ) {
            @include align-menu-text-to-edges-while-padding-sticks-out;
        }

        .more {
            @include breakpoint( $menu-breakpoint-show-all-items ) {
                display: none !important;
            }

            button {
                padding: $menu-more-button-padding;
                color: $menu-more-button-color;
                border-color: $menu-more-button-border-color;

                @at-root body.is-light & {
                    color: $menu-more-button-color-dark;
                    border-color: $menu-more-button-border-color-dark;
                }

                @at-root body.is-dark & {
                    color: $menu-more-button-color-light;
                    border-color: $menu-more-button-border-color-light;
                }
            }
        }

        @include breakpoint( $menu-breakpoint-show-priority-3-items ) {
            // Targeted element is neither .more, nor .home, nor ...
            // The selector could also be written as
            //     li:not( .more, .home, .priority-1, .priority-2, .priority-3 )
            // but browser support is lacking.
            li:not( .more ):not( .home ):not( .priority-1 ):not( .priority-2 ):not( .priority-3 ) {
                display: none !important;
            }
        }

        @include breakpoint( $menu-breakpoint-show-priority-2-items ) {
            // The selector could also be written as
            //     li:not( .more, .home, .priority-1, .priority-2 )
            // but browser support is lacking.
            li:not( .more ):not( .home ):not( .priority-1 ):not( .priority-2 ) {
                display: none !important;
            }
        }

        @include breakpoint( $menu-breakpoint-show-priority-1-items ) {
            // The selector could also be written as
            //     li:not( .more, .home, .priority-1 )
            // but browser support is lacking.
            li:not( .more ):not( .home ):not( .priority-1 ) {
                display: none !important;
            }
        }
    }

}


// Off-canvas navigation
.off-canvas {
    &.glass {
        @include frosted-glass( 0.93, "light" );

        @at-root body.is-dark & {
            @include frosted-glass( 0.93, "dark" );
        }
    }

    .home {
        margin-top: $global-margin;
        line-height: 1;
    }

    a, button, div {
        display: block;
        padding: $menu-items-padding;
        margin-bottom: 0;

        line-height: 1;
        text-decoration: none;
    }

    li.active {
        a, div {
            color: $menu-item-color-active;
            background: $menu-item-background-active;
        }

        &.active-child {
            a, div {
                color: $anchor-color;
                background-color: rgba( $menu-item-background-active, 0.2 );
            }
        }
    }

    .close-button {
        position: absolute;
        top: rem-calc( -0.5 );
        right: 0;

        &:focus {
            outline: none;
        }
    }
}
